import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    template: `
        <style>
            .buttons-container {
                display: flex;
                margin-top: 20px;
                align-items: center;
                justify-content: space-evenly;
                align-content: center;
            }
        </style>
        <div class="popup">
            <h2 class="callout">You have multiple account types connected to this user</h2>
            <div>Please choose an account type below</div>
            <div class="buttons-container">
                <ion-button color="success" (click)="loginAs('student')">Student</ion-button>
                <ion-button color="primary" (click)="loginAs('teacher')">Teacher</ion-button>
            </div>
        </div>
    `
})
export class MatEdlinkChooseUserComponent {

    constructor(
        public dialogRef: MatDialogRef<MatEdlinkChooseUserComponent>
    ) { }

    loginAs(type: string) {
        this.dialogRef.close(type);
    }
}
