import {
    Component,
    Input,
    Output,
    EventEmitter,
    NgZone,
    OnChanges,
    OnDestroy,
    ViewChild,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';
import { Overlay } from '@angular/cdk/overlay';
import { Notify } from '../../notify.service';
import { Timer } from '../timer';
import { AbstractRecorder } from './abstract-recorder';
import { iOSVideoRecorder } from './ios-video-recorder';
import { WebRecorder } from './web-recorder';
import { RecordingOverlayService } from './recording-overlay.service';
import { DeviceService } from 'app/shared/device.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js';
import * as WaveSurfer from 'wavesurfer.js';
WaveSurfer.Microphone = MicrophonePlugin;
/**
 * The MediaRecorder component is shown after the user hits "start response", and is
 * responsible for capturing video/audio from the user and emitting the final files.
 * The component allows the user to preview while recording, start, stop, and re-record.
 *
 * On web (and Android) it contains a video tag for preview, and uses RecordRTC to get the files
 * On iOS it creates an iOS Recorder Overlay for preview, and uses the iOS Media Recorder Service to use a plugin
 *
 */
@Component({
    selector: 'media-recorder',
    templateUrl: './media-recorder.component.html',
})
export class MediaRecorderComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() type: 'video' | 'audio' | 'screenAndCamera' | 'screenAndMicrophone';
    @Input() rerecordAllowed: boolean;
    @Input() disabled: boolean = false;
    @Input() usedIn: 'videoCheck' | 'attempt' = 'attempt';
    @Input() showCountdown: boolean = true;
    @Input() mediaPlayerHidden: boolean = false;
    @Input() gradebookStyles: boolean = false;
    @Input() destoryIosPlayer: BehaviorSubject<boolean>;
    @Input() captureSpeakerAndMicrophone: boolean = false;
    /**
     * The response timer that dictates remaining time to record. If this isn't provided, we count up.
     */
    @Input() responseTimer: Timer;
    @Input() responseTimeValue: number;
    @Input() minimumResponse: number;
    @Input('APmode') APMode: boolean;
    @Input() startStopRecording: BehaviorSubject<boolean>;
    @Output() capture = new EventEmitter<[Blob, number, string[]]>();
    @Output() iosVideoCapture = new EventEmitter<[Blob, string, number, string[]]>();
    @Output() fatalError = new EventEmitter<[string, any, string[]]>();
    @Output() isRecording = new EventEmitter<boolean>();
    @Output() recordingClicked = new EventEmitter<boolean>();
    @Output() ready = new EventEmitter<boolean>();
    @Output() cancelRecorder = new EventEmitter<void>();

    @ViewChild('video', { static: false }) video: any;
    @ViewChild('audio', { static: false }) audio: any;

    player: HTMLVideoElement | HTMLAudioElement;

    recorder: AbstractRecorder;
    overlayRef;

    wavesurfer;
    microphone;

    countdownInterval: any;
    iosCountdown: number = 3;
    recording = false;
    disabledFlag = false;
    disabledStopFlag = false;

    constructor(
        public zone: NgZone,
        public notify: Notify,
        public dialog: MatDialog,
        public overlay: Overlay,
        public recordingOverlay: RecordingOverlayService,
        public deviceService: DeviceService,
        public modal: ModalController,
        public router: Router
    ) { }

    ngOnInit() {
        if (this.deviceService.getPlatform() === 'ios') {
            if (this.type === 'video') {
                this.recorder = new iOSVideoRecorder(this, this.deviceService, this.router);
                this.startRecording();
            } else {
                this.recorder = new WebRecorder(this, this.deviceService, this.router);
            }
        } else {
            this.recorder = new WebRecorder(this, this.deviceService, this.router);
        }
    }

    ngOnChanges() {
        this.disabledFlag = this.disabled;
    }

    ngAfterViewInit() {
        this.player = this.video ? this.video.nativeElement : this.audio.nativeElement;
        // Do this in another task because it requires its own CD run
        Promise.resolve().then(() => {
            this.recorder.connectAndPreview();
        });

        if (this.startStopRecording) {
            this.startStopRecording.subscribe(res => {
                if (res === null) {
                    return;
                }

                if (res) {
                    this.startRecording();
                } else {
                    this.stopRecording();
                }
            });
        }
    }

    setPlayerMode(mode: 'preview' | 'content') {
        if (mode === 'preview') {
            this.player.autoplay = true;
            this.player.controls = false;
            this.player.muted = true;
        } else {
            this.player.autoplay = false;
            this.player.controls = true;
            this.player.muted = false;
        }
    }

    iosRecCountdown(): Promise<boolean> {
        return new Promise(res => {
            this.countdownInterval = setInterval(() => {
                this.iosCountdown--;
                if (this.iosCountdown === 0) {
                    return res(true);
                }
            }, 1000);
        });
    }

    startRecording() {
        this.recordingClicked.emit(true);
        this.recording = true;
        if (this.deviceService.getPlatform() === 'ios' && this.type === 'video') {
            this.iosRecCountdown().then(finished => {
                if (finished) {
                    this.iosCountdown = 3;
                    clearInterval(this.countdownInterval);
                    this.recorder.start();
                    this.setMinimumResponseTime()
                }
            });
        } else {
            if (this.showCountdown) {
                this.recordingOverlay.showOverlay().then(() => {
                    this.startRecorder();
                });
            } else {
                this.startRecorder();
            }
        }
    }

    stopRecording() {
        if (!this.deviceService.isIos() && this.type === 'audio') {
            this.destroyWave();
        }

        this.recording = false;
        this.recorder.stop();
    }

    reRecord() {
        this.recordingClicked.emit(true);
        this.recording = true;

        if (this.type === 'audio') {
            this.destroyWave();
        }

        if (this.showCountdown) {
            this.recordingOverlay.showOverlay().then(() => {
                this.startRecorder();
            });
        } else {
            this.startRecorder();
        }
    }

    private showWave() {
        if (!this.deviceService.isIos()) {
            requestAnimationFrame(() => {
                this.wavesurfer = WaveSurfer.create({
                    hideScrollbar: true,
                    container: '#wavesurferContainer',
                    waveColor: '#686868',
                    interact: false,
                    cursorWidth: 0,
                    audioCenter: true,
                    plugins: [MicrophonePlugin.create()]
                });

                this.wavesurfer.microphone.on('deviceReady', stream => {
                    console.log('Device ready!', stream);
                });
                this.wavesurfer.microphone.on('deviceError', code => {
                    console.warn('Device error: ' + code);
                })

                this.microphone = this.wavesurfer.microphone;

                // start the microphone
                this.microphone.start();
            });
        }
    }

    setMinimumResponseTime() {
        if(this.minimumResponse && this.minimumResponse !== 0) {
            this.disabledStopFlag = true;
            setTimeout(() => {
                this.disabledStopFlag = false;
            }, this.minimumResponse * 1000);
        }
    }

    destroyWave() {
        if (!this.deviceService.isIos()) {
            if (this.microphone) {
                this.microphone.stop();
            }

            if (this.wavesurfer) {
                this.wavesurfer.destroy();
            }
        }
    }

    private startRecorder() {
        if (this.type === 'audio') {
            this.showWave();
        }
        this.recorder.rerecord();
        this.setMinimumResponseTime();
    }

    ngOnDestroy() {
        if (this.type === 'video') {
            this.video.nativeElement.remove();
        }

        if (!this.deviceService.isIos()) {
            if (this.type === 'audio') {
                this.destroyWave();
            }

            this.recorder.destroyTracks();
            this.recorder.stop();
        } else {
            clearInterval(this.countdownInterval);

            if (this.recording) {
                this.recorder.stop();
            }
        }
    }
}
