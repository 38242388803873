import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { SettingsService } from './settings.service';

@Injectable()
export class RequireAuthGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router,
        private settings: SettingsService
    ) { }

    canActivate() {
        if (this.auth.syncToken) {
            console.log('User has a token...');
            return true;
        } else {
            console.log('User didn\'t have token...');
            this.router.navigate(['/']);
        }
    }
}
