import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    templateUrl: './stop-cheating-popup.html'
})

export class StopCheatingPopupComponent {
    understood: boolean = false;
    constructor(private modal: ModalController) { }

    close() {
        this.modal.dismiss();
    }
}
