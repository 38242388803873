import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { LoaderService } from './loader.service';

@Component({
    templateUrl: './mat-send-to-lms.html',
})
export class MatSendToLMSComponent {
    error: string = '';
    syncData: any;

    constructor(
        public dialogRef: MatDialogRef<MatSendToLMSComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { assessmentId: string, tokenId: string },
        private http: HttpClient,
        private loaderService: LoaderService
    ) {
        this.loaderService.showLoading();
        this.http.get<any>(`${environment.NApiDomain}/instructor/gradebook/check-lti-students/${this.data.assessmentId}`).subscribe(checkLtiStudentsRes => {
            if (checkLtiStudentsRes.error) {
                this.loaderService.hideLoading();
                this.error = checkLtiStudentsRes.error;
                return;
            }

            let ltiNotConnectedStudents = checkLtiStudentsRes.data.filter(x => !x.LtiUserId);
            if (ltiNotConnectedStudents.length > 0) {
                this.error += `Please remember that students must access each assignment from within Canvas so that information can be shared between systems.<br/> The following ${checkLtiStudentsRes.data.length > 1 ? 'students' : 'student'} didn't access from Canvas: `
                for (let i = 0 ; i < ltiNotConnectedStudents.length; i++ ) {
                    this.error += `<strong>${ltiNotConnectedStudents[i].FirstName} ${ltiNotConnectedStudents[i].LastName} (${ltiNotConnectedStudents[i].UserName})<strong>`;

                    if (i !== ltiNotConnectedStudents.length - 1) {
                        this.error += ', '
                    } else {
                        this.error += '.'
                    }
                }
                this.error += '<br/>';
            }

            this.http.get<any>(`${environment.CMSDomain}/Grading/GradeNewPassback`, {params: {skillId: this.data.assessmentId, tokenId: this.data.tokenId}}).subscribe(res => {
                if (res.Status || res.Status === 0) {
                    this.loaderService.hideLoading();
                    this.error = res.Message;
                    return;
                }

                this.syncData = JSON.parse(res);
                if (!this.syncData || !this.syncData.UsersGradeInfoModel || this.syncData.UsersGradeInfoModel.length == 0) {
                    this.loaderService.hideLoading();
                    this.error = 'We experienced an error while fetching your Canvas data. Please try again';
                    return;
                }

                let studentsNotSendSuccessfully = this.syncData.UsersGradeInfoModel.filter(x => !x.SentSuccessfully);
                if (studentsNotSendSuccessfully.length > 0) {
                    for (let i = 0 ; i < studentsNotSendSuccessfully.length; i++ ) {
                        this.error += `Error for student <strong>${studentsNotSendSuccessfully[0].FirstName} ${studentsNotSendSuccessfully[0].LastName} (${studentsNotSendSuccessfully[0].UserName})</strong>: ${studentsNotSendSuccessfully[0].Message}<br/>`;
                    }
                }

                this.loaderService.hideLoading();
            }, err => {
                console.log('Sync to LMS error', err);
                this.error = err.Message;
                this.loaderService.hideLoading();
            });
        });
    }

    done() {
        this.error = null;
        this.dialogRef.close(true);
    }

    cancel() {
        this.error = null;
        this.dialogRef.close(false);
    }
}
