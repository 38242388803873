import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { NavService } from './shared/nav.service';
import { DeviceService } from './shared/device.service';
import { ModalController, Platform } from '@ionic/angular';
import { Network } from '@capacitor/network';
import { Subscription } from 'rxjs';
import { AuthService } from './shared/auth.service';
import { DataService } from './student/data.service';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from './shared/loader.service';
import { AddClassModal } from './student/auth/add-class-modal.component';
import { Intercom } from 'ng-intercom';
import { SocketService } from './shared/socket.service';
import { App } from '@capacitor/app';
import { SplashScreen } from  '@capacitor/splash-screen'
import { CurrentModuleService } from './current-module.service';
import { UserInactivityService } from './shared/user-inactivity.service';
import { UserSessionService } from './shared/user-session.service';

import * as crypto from 'crypto-js';

declare var ga: any;
declare var hj: any;

import '../assets/keymanweb/keymanweb.js';
import '../assets/keymanweb/kmwuitoggle.js';
declare var keyman: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    environment = environment;
    offlineStatus: boolean = false;
    lowInternetConnection: boolean = false;
    disconnectSubscription: Subscription;
    connectSubscription: Subscription;
    currentModule: string;
    ssoUser: boolean;
    showFab: boolean;
    checkConnection: boolean;
    displayFooter: boolean = true;
    displayHeader: boolean = true;
    isInstructorStudent: boolean;
    mainAppClasses: string = '';

    time: any;
    inactivityTime: number;

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public title: Title,
        public zone: NgZone,
        public navService: NavService,
        public deviceService: DeviceService,
        public data: DataService,
        public http: HttpClient,
        public loaderService: LoaderService,
        public intercom: Intercom,
        private platform: Platform,
        private authService: AuthService,
        private modal: ModalController,
        private socketService: SocketService,
        private moduleService: CurrentModuleService,
        private userInactivityService: UserInactivityService,
        private userSessionService: UserSessionService
    ) {
        keyman.init({
            attachType: 'manual',
        });

        this.initializeApp();
        // Setting up main color before we take the whitebranding schema
        document.documentElement.style.setProperty('--dark-main', 'rgb(15, 99, 150)');
        document.documentElement.style.setProperty('--light-main', '#25a9e0');

        ga('create', environment.analyticsProperty, 'auto');

        if (this.deviceService.isApp()) {
            SplashScreen.hide();
            this.platform.backButton.subscribeWithPriority(10, () => {
                this.navService.back();
            });

            App.addListener('appStateChange', appState => {
                this.userSessionService.userInApp.next({inApp: appState.isActive, token: this.authService.syncToken});
            });

            Network.addListener('networkStatusChange', status => {
                if (status.connected) {
                    this.offlineStatus = false;
                } else {
                    this.offlineStatus = true;
                }
            });
        } else {
            window.addEventListener('online', () => {
                this.offlineStatus = false;
            });
            window.addEventListener('offline', () => {
                if (this.checkConnection) {
                    this.offlineStatus = true;
                }
            });

            const connection = navigator['connection']
                || navigator['mozConnection']
                || navigator['webkitConnection'];

            if (connection) {
                connection.addEventListener('change', () => {
                    if (connection.downlink === 10 || connection.downlink === 0) {
                        // This is a bug when the user goes offline. Do nothing in this case
                    } else if (connection.downlink <= 0.2 && this.checkConnection) {
                        this.offlineStatus = false;
                        this.lowInternetConnection = true;
                    } else {
                        this.offlineStatus = false;
                        this.lowInternetConnection = false;
                    }
                    console.log('Catching downlink:', connection.downlink, 'Mb/s');
                });
            } else {
                console.log('No navgator connection');
            }
        }

        this.authService.ssoUser.subscribe(sso => {
            this.ssoUser = sso;
        });

        this.authService.isInstructorStudent.subscribe(isInstructorStudent => {
            this.isInstructorStudent = isInstructorStudent;
        });

        this.moduleService.getCurrentModule().subscribe(module => {
            this.currentModule = module;
        });

        this.authService.token.subscribe(tokenRes => {
            if (tokenRes) {
                this.http.get<any>(`${environment.NApiDomain}/`)
            }
        });

        router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((n: NavigationEnd) => {
            const pageTitle = this.getDeepestTitle(router.routerState.snapshot.root);

            if (n.urlAfterRedirects === '/classes') {
                this.showFab = true;
            } else {
                this.showFab = false;
            }

            if (
                n.urlAfterRedirects.includes('portfolio') ||
                n.urlAfterRedirects.includes('attempt') ||
                (n.urlAfterRedirects.includes('sync-room') &&
                !n.urlAfterRedirects.includes('lobby'))
            ) {
                this.checkConnection = false;
            } else {
                this.checkConnection = true;
            }

            if (n.urlAfterRedirects.includes('new')) {
                this.displayHeader = false;
            } else {
                this.displayHeader = true;
            }

            if (
                (n.urlAfterRedirects.includes('sync-room') &&
                !n.urlAfterRedirects.includes('lobby')) ||
                n.urlAfterRedirects.includes('video-check') ||
                n.urlAfterRedirects.includes('demo') ||
                n.urlAfterRedirects.includes('gradebook')
            ) {
                this.displayFooter = false;
            } else {
                this.displayFooter = true;
            }

            if (pageTitle) {
                title.setTitle(pageTitle);
            } else if (pageTitle !== false) {
                title.setTitle('Extempore');
            }

            if (n.urlAfterRedirects.includes('/instructor/')
                || n.urlAfterRedirects.includes('/admin/')
                || n.urlAfterRedirects.includes('portfolio')) {
                this.intercom.shutdown();
                this.intercom.boot({
                    app_id: environment.intercomAppId
                });
            }

            window.scrollTo(0, 0);
            ga('send', 'pageview', n.urlAfterRedirects);
            // Sometimes this isn't loaded fast enough
            window['hj'] = window['hj'] || function () { (hj.q = hj.q || []).push(arguments) };
            if (!!hj) {
                hj('vpv', n.urlAfterRedirects);
            }
        });
    }

    ngOnInit() {
        this.resetTimer();

        this.authService.token.subscribe(token => {
            if (token) {
                this.http.get(`${environment.NApiDomain}/${token}/profile`).subscribe((data: any) => {
                    if (data && data.FirstName && data.LastName && data.Email && !data.UserName.includes('-student')) {
                        if (!data.IntercomInternalContactId) {
                            this.http.post<any>(`${environment.NApiDomain}/create-intercom-contact`, {intercomExternalContactId: data.IntercomExternalContactId, username: data.UserName,
                                email: data.Email, name: data.FirstName + " " + data.LastName, role: "student", creationDate: data.CreatedOn, edlinkId: data.EdlinkId})
                                .subscribe(res => {
                                if (res.success) {
                                    data.IntercomInternalContactId = res.intercomInternalContactId;
                                    data.IntercomExternalContactId = res.intercomExternalContactId;
                                    let hash = crypto.HmacSHA256(data.IntercomExternalContactId, environment.intercomSecretKey).toString();

                                    this.intercom.boot({
                                        app_id: environment.intercomAppId,
                                        widget: {
                                            "activator": "#intercom"
                                        },
                                        name: `${data.FirstName} ${data.LastName}`,
                                        user_id: data.IntercomExternalContactId,
                                        email: data.Email,
                                        username: data.UserName,
                                        school_name: data.Name,
                                        role: 'user',
                                        user_hash: hash
                                    });
                                }
                            });
                        } else {
                            let hash = crypto.HmacSHA256(data.IntercomExternalContactId, environment.intercomSecretKey).toString();

                            this.intercom.boot({
                                app_id: environment.intercomAppId,
                                widget: {
                                    "activator": "#intercom"
                                },
                                name: `${data.FirstName} ${data.LastName}`,
                                user_id: data.IntercomExternalContactId,
                                email: data.Email,
                                username: data.UserName,
                                school_name: data.Name,
                                role: 'user',
                                user_hash: hash
                            });
                        }
                    }
                });
                this.http.get(`${environment.NApiDomain}/${token}/login-date`).subscribe();
            } else {
                this.intercom.shutdown();
                this.intercom.boot({
                    app_id: environment.intercomAppId
                });
            }
        });
    }

    @HostListener('document:mousemove')
    @HostListener('document:keypress')
    @HostListener('document:click')
    @HostListener('document:wheel')
    @HostListener('document:touchstart')
    resetTimer() {
        if (!this.userInactivityService.inactivityPeriod) {
            return;
        }

        clearTimeout(this.time);
        this.time = setTimeout(() => {
            this.userInactivityService.inactivityBehaviorSubject.next(true);
        }, this.userInactivityService.inactivityPeriod * 60000);
    }

    initializeApp() {
        App.addListener('appUrlOpen', (data: any) => {
            this.zone.run(() => {
                console.log('Deep link URL:', data.url);
                const slug = data.url.split('extempore://').pop();
                this.loaderService.showLoading();

                if (
                    slug.includes('token') &&
                    slug.includes('classGuid') &&
                    slug.includes('assessmentId') &&
                    slug.includes('questionId') &&
                    slug.includes('roomId') &&
                    slug.includes('async-room')
                ) {
                    let data = slug.split('&');
                    let tokenId = data[1].split('_').pop();
                    let classGuid = data[2].split('_').pop();
                    let assessmentId = data[3].split('_').pop();
                    let questionId = data[4].split('_').pop();
                    let roomId = data[5].split('_').pop();

                    this.authService.autoLogin(tokenId).then(() => {
                        this.router.navigateByUrl(`/classes/${classGuid}/${assessmentId}/${questionId}/room/${roomId}`);
                        this.loaderService.hideLoading();
                    }, err => {
                        this.loaderService.hideLoading();
                    });
                } else if (
                    slug.includes('token') &&
                    slug.includes('classGuid') &&
                    slug.includes('assessmentId') &&
                    slug.includes('questionId') &&
                    slug.includes('roomId') &&
                    slug.includes('sync-room')
                ) {
                    let data = slug.split('&');
                    let tokenId = data[1].split('_').pop();
                    let classGuid = data[2].split('_').pop();
                    let assessmentId = data[3].split('_').pop();
                    let questionId = data[4].split('_').pop();
                    let roomId = data[5].split('_').pop();

                    this.authService.autoLogin(tokenId).then(() => {
                        this.router.navigateByUrl(`/classes/${classGuid}/${assessmentId}/${questionId}/sync-room/${roomId}/lobby`);
                        this.loaderService.hideLoading();
                    }, err => {
                        this.loaderService.hideLoading();
                    });
                } else if (slug.includes('token') && slug.includes('classGuid') && slug.includes('assessmentId')) {
                    let data = slug.split('&');
                    let tokenId = data[0].split('_').pop();
                    let classGuid = data[1].split('_').pop();
                    let assessmentId = data[2].split('_').pop();
                    this.authService.autoLogin(tokenId).then(() => {
                        this.router.navigateByUrl(`/classes/${classGuid}/${assessmentId}`);
                        this.loaderService.hideLoading();
                    }, err => {
                        this.loaderService.hideLoading();
                    });
                } else if (slug.includes('token') && slug.includes('classGuid')) {
                    let data = slug.split('&');
                    let tokenId = data[0].split('_').pop();
                    let classGuid = data[1].split('_').pop();
                    this.authService.autoLogin(tokenId).then(() => {
                        this.router.navigateByUrl(`/classes/${classGuid}`);
                        this.loaderService.hideLoading();
                    }, err => {
                        this.loaderService.hideLoading();
                    });
                } else if (slug.includes('token')) {
                    const tokenId = slug.split('_').pop();
                    console.log(`Token is ${tokenId}`);
                    this.authService.autoLogin(tokenId).then(() => {
                        this.loaderService.hideLoading();
                    });
                } else if (slug.includes('class')) {
                    const classId = slug.split('_').pop();
                    console.log(`Class is ${classId}`);
                    this.loaderService.showLoading();
                    this.data.refreshData().then(() => {
                        this.loaderService.hideLoading();
                        this.router.navigateByUrl(`/classes/${classId}`);
                    });
                } else if (slug.includes('account')) {
                    this.router.navigateByUrl('/account');
                    this.loaderService.hideLoading();
                } else {
                    this.router.navigateByUrl('/');
                    this.loaderService.hideLoading();
                }
            });
        });
    }

    addClass() {
        const addClassModal = this.modal.create({
            component: AddClassModal,
            cssClass: 'add-class-modal'
        });

        addClassModal.then(modal => {
            modal.present();
            modal.onDidDismiss().then(res => {
                if (res.data) {
                    this.loaderService.showLoading();
                    this.data.refreshData().then(() => {
                        this.loaderService.hideLoading();
                    });
                }
            });
        });
    }

    getDeepestTitle(route: ActivatedRouteSnapshot): string | false | null {
        const titles: (string | false)[] = [];
        let current = route;

        // Descend the tree collecting titles
        while (current) {
            // Find any page titles that aren't undefined (string or array)
            if (current.data['title'] !== undefined) {
                titles.push(current.data['title']);
            }
            current = current.children[0];
        }
        if (titles.length > 0) {
            return titles[titles.length - 1];
        } else {
            return null;
        }
    }

    ngOnDestroy() {
        this.disconnectSubscription.unsubscribe();
        this.connectSubscription.unsubscribe();
    }
}
