import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from 'app/shared/device.service';

@Component({
    template: `
        <h1 mat-dialog-title>Error Connecting to
            <span *ngIf="data.type === 'screenAndCamera'">screen share and camera</span>
            <span *ngIf="data.type === 'screenAndMicrophone'">screen share</span>
            <span *ngIf="data.type === 'video'">camera</span>
            <span *ngIf="data.type === 'audio'">microphone</span>
            - {{data.reason}}
        </h1>
         <div *ngIf="deviceService.isApp && !deviceService.isOnMobile; else onBrowser">
            <p>
                We couldn't connect to your camera and microphone. Plese go to settings and give this app permissions for camera and microphone.
                After that come back to the app and click retry
            </p>
        </div>
        <ng-template #onBrowser>
            <p>{{error}}</p>
        </ng-template>
        <div mat-dialog-actions>
            <ion-button tabindex="0" mat-dialog-close color="primary">Retry</ion-button>
        </div>
    `
})
export class HardwareFailureComponent {
    error: string;
    NOT_ENOUGH_PERMISSIONS = 'Not Enough Permissions';

    constructor(
        public deviceService: DeviceService,
        public dialogRef: MatDialogRef<HardwareFailureComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { type: string, reason: string }
    ) {
        console.log('Got into HardwareFailureComponent with reason', data.reason);
        if (data.reason == 'NotFoundError' || data.reason == 'DevicesNotFoundError') {
            this.error = 'We couldn\'t find your camera. Please be sure to connect one and click retry';
        } else if (data.reason == 'NotReadableError' || data.reason == 'TrackStartError') {
            this.error = 'Your camera is in use by another program like Skype or Zoom. Please turn off the camera there and click retry';
        } else if (data.reason == 'NotAllowedError' || data.reason == 'PermissionDismissedError') {
            if (data.type === 'screenAndCamera') {
                this.error = 'Please enable both camera and screen sharing access. Click the camera icon to activate your camera and the screen share icon to share your screen. This will allow you to include both video and screen content in your response.';
            } else if (data.type === 'screenAndMicrophone') {
                this.error = 'Please enable screen sharing by clicking the screen share icon. This will allow you to share your screen as part of your response.';
            } else {
                this.error = 'Please allow the app to access your camera by clicking the camera icon in the top left corner. This will enable you to record your response.';
            }
        } else {
            this.error = 'There was a problem while trying to connect to your camera and microphone. Please try again later.';
        }
    }
}
