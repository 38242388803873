import { Component, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { switchMap, startWith, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AccountTypeService } from './account-type.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AlertController } from '@ionic/angular';
import { IonicAlertsService } from 'app/ionic-alerts.service';
import { LoaderService } from './loader.service';
import { Pricing } from './pricing';

@Component({
    selector: 'subscription-table',
    templateUrl: './subscription-table.component.html'
})

export class SubscriptionTableComponent implements OnDestroy {
    @Input('customerId') customerId: string;
    @Input('canUpgrade') canUpgrade: boolean;
    @Input('showButtons') showButtons: boolean;
    @Input('syncToken') syncToken: string;
    @Input('stickyTop') stickyTop: boolean;
    @Input('liteAccount') liteAccount: boolean;
    @Output() changed = new EventEmitter<boolean>();
    accountInfo: Observable<any>;
    studentPaidPrice: number;

    constructor(
        public dialog: MatDialog,
        public alert: AlertController,
        public http: HttpClient,
        public accountTypeService: AccountTypeService,
        public route: ActivatedRoute,
        public title: Title,
        private alertService: IonicAlertsService,
        private loaderService: LoaderService,
        public pricing: Pricing
    ) {
        this.accountInfo = route.params.pipe(
            switchMap(params => {
                this.syncToken = params['token'];
                localStorage.setItem('CMSToken', this.syncToken);
                return http.get<any>(`${environment.NApiDomain}/instructor/${this.syncToken}`).pipe(
                    map(account => {
                        title.setTitle(`Account Details | Extempore`);
                        account.data['accountType'] = this.accountTypeService.getAccountType(account.data);
                        return account.data;
                    })
                );
            })
        );

        if (this.customerId) {
            this.loaderService.showLoading();
            this.pricing.getStudentPaidPrice(this.customerId).subscribe(res => {
                if (res.success) {
                    this.studentPaidPrice = res.studentPaidPrice;
                } else {
                    this.alertService.createGenericAlert('Error', 'We expirienced an error while fetching student paid price. Please try again');
                }
                this.loaderService.hideLoading();
            });
        } else {
            this.studentPaidPrice = this.pricing.getDefaultStudentPaidPrice();
        }
    }

    goToSales() {
        window.open('http://landing.extemporeapp.com/contact-sales', '_blank');
    }

    switchToStudentPaid(customerId: string) {
        const switchToSpCallback = () => {
            console.log('Changing license model');
            this.loaderService.showLoading();
            this.http.post<any>(`${environment.NApiDomain}/account/${customerId}/license`, {}).subscribe(success => {
                if (success.success) {
                    this.loaderService.hideLoading();
                    this.accountInfo = this.http.get<any>(`${environment.NApiDomain}/instructor/${this.syncToken}`).pipe(
                        map(account => {
                            account.data['accountType'] = this.accountTypeService.getAccountType(this.accountInfo);
                            return this.accountInfo;
                        })
                    );
                    this.changed.emit(true);
                } else {
                    this.alertService.createGenericAlert('Error', 'There was an error while switching to Student Paid Subscription Model. Please try again later');
                }
            });
        }

        this.alertService.createConfirmAlert('Switching to Student-Funded Plan', `You are about to switch to a Student-Funded Plan. This means that students will be asked to pay $${this.studentPaidPrice/100} when joining your class.`, switchToSpCallback)
    }

    ngOnDestroy() {
        this.loaderService.hideLoading();
    }
}
