import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable, NgZone } from "@angular/core";
import { environment } from "environments/environment";
import { IonicAlertsService } from "app/ionic-alerts.service";
import { NavService } from "./nav.service";

@Injectable()
export class UserSessionService {
    inAttempt: EventEmitter<{inAttempt: boolean, questionId: number, token: string}> = new EventEmitter<{inAttempt: boolean, questionId: number, token: string}>();
    inRoomAttempt: EventEmitter<{inAttempt: boolean, questionId: number, token: string}> = new EventEmitter<{inAttempt: boolean, questionId: number, token: string}>();
    userInApp: EventEmitter<{inApp: boolean, token: string}> = new EventEmitter<{inApp: boolean, token: string}>();
    private sessionId: number;
    private attemptSessionId: number;

    constructor(
        private http: HttpClient,
        private alertService: IonicAlertsService,
        private navService: NavService,
        private ngZone: NgZone
    ) {
        this.inAttempt.subscribe(res => {
            if (!res?.token) {
                return;
            }

            localStorage.setItem('userInAttempt', JSON.stringify({inAttempt: res.inAttempt}));
            this.userInAttemptToggle(res);
            this.userInAttemptSession(res.inAttempt, res.token, res.questionId);
        });

        this.inRoomAttempt.subscribe(res => {
            if (!res.token) {
                return;
            }

            localStorage.setItem('userInAttempt', JSON.stringify({inAttempt: res.inAttempt}));
            this.userInAttemptSession(res.inAttempt, res.token, res.questionId);
        });

        this.userInApp.subscribe(res => {
            if (!res.token) {
                return;
            }

            this.userInAppSession(res.inApp, res.token);
        });
    }

    private userInAttemptToggle(res: {inAttempt: boolean, questionId: number, token: string}) {
        if (!res || !res.questionId) {
            return
        }

        if (res.inAttempt) {
            // in attempt
            console.log(`User with token ${res.token} is in attempt for question ${res.questionId}`);
            this.http.post<any>(`${environment.NApiDomain}/in-attempt`, {token: res.token, questionId: res.questionId})
            .subscribe(() => { },
            error => {
                console.error('Error when sending in attempt:', error.message);
            });
        } else {
            // out of attempt
            console.log(`User with token ${res.token} is out of attempt for question ${res.questionId}`);
            this.http.post<any>(`${environment.NApiDomain}/out-of-attempt`, {token: res.token, questionId: res.questionId})
            .subscribe(() => { },
            error => {
                console.error('Error when sending out of attempt:', error.message);
            });
        }
    }

    /**
     * Used only for mobile apps, for web we are handling it through sockets
     * @param inApp boolean: true if the user is getting in, false if he's stopping the app
     * @param token
     */
    private userInAppSession(inApp: boolean, token: string) {
        let body = {start: inApp};
        if (this.sessionId) {
            body['sessionId'] = this.sessionId
        }

        if (!inApp) {
            this.http.post<any>(`${environment.NApiDomain}/out-of-all-attempts/${token}`, {})
            .subscribe(() => { },
            error => {
                console.error('Error when sending out of all attempts:', error.message);
            });

            this.userInAttemptSession(false, token);
            this.ngZone.run(() => {
                const inAttemptRes = JSON.parse(localStorage.getItem('userInAttempt'));
                if (inAttemptRes?.inAttempt) {
                    localStorage.setItem('bypassGuard', JSON.stringify(true));

                    this.navService.backWithPromise().then(() => {
                        this.alertService.closeAll();
                        localStorage.removeItem('bypassGuard');
                    });
                }
            });
        }

        this.http.post<any>(`${environment.NApiDomain}/user-session/${token}`, body).subscribe(res => {
            if (!res.success) {
                return;
            }

            // We get session id as a result only when starting a session. When stopping we should remove the old session id from in-memory
            if (res.sessionId) {
                this.sessionId = res.sessionId;
            } else {
                this.sessionId = null;
            }
        });
    }

    private userInAttemptSession(inAttempt: boolean, token: string, questionId?: number) {
        let body = {
            start: inAttempt
        };

        if (this.attemptSessionId) {
            body['sessionId'] = this.attemptSessionId
        } else if (questionId) {
            body['questionId'] = questionId;
        } else {
            return;
        }

        this.http.post<any>(`${environment.NApiDomain}/attempt-user-session/${token}`, body).subscribe(res => {
            if (!res.success) {
                return;
            }

            // We get session id as a result only when starting an attempt session. When stopping we should remove the old session id from in-memory
            if (res.sessionId) {
                this.attemptSessionId = res.sessionId;
            } else {
                this.attemptSessionId = null;
            }
        });
    }
}
