import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SyncRoomTaskDetails } from "./sync-room-task.component";

@Component({
    selector: 'sync-room-task-popup',
    templateUrl: './sync-room-task-popup.component.html',
})

export class SyncRoomTaskPopupComponent {
    assessment: number;
    question: number;
    inRoom: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { assessment: number, question: number, inRoom: boolean },
        private dialogRef: MatDialogRef<SyncRoomTaskPopupComponent>
    ) {
        this.question = data.question;
        this.assessment = data.assessment;
        this.inRoom = data.inRoom;
    }
    close() {
        this.dialogRef.close();
    }
}