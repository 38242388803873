import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'customDatePipe'})
export class CustomDatePipe implements PipeTransform {
    transform(date: string): string {
        if (!date) {
            return '';
        }

        date = date.replace('T', ' ');
        date = date.split('.')[0];
        let day;
        let time;
        [day, time] = date.split(' ');

        let hours;
        let minutes;
        let seconds;
        [hours, minutes, seconds] = time.split(':');
        if (hours === '00') {
            return `${day} 12:${minutes}:${seconds} AM`;
        } else if (hours > 12) {
            return `${day} ${hours - 12}:${minutes}:${seconds} PM`;
        } else {
            return `${day} ${hours}:${minutes}:${seconds} AM`;
        }
    }
}
