import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Notify } from './notify.service';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const notify = this.injector.get(Notify);
        notify.log('HTTP Request made to ' + req.urlWithParams);
        return next.handle(req);
    }
}
