import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Assessment, DataService, Question } from "app/student/data.service";
import { ExpandMediaModalComponent } from "app/student/questions/expand-media-modal.component";
import { combineLatest, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { DeviceService } from "./device.service";
import { LoaderService } from "./loader.service";
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

import * as moment from "moment";

@Component({
    selector: 'sync-room-task',
    templateUrl: './sync-room-task.component.html'
})
export class SyncRoomTaskDetails implements OnInit, OnDestroy {
    @Input('assessmentId') assessmentId: any;
    @Input('questionId') questionId: any;
    @Input('inRoom') inRoom: boolean;
    @Input('inGrades') inGrades: boolean;
    @Input('isInstructor') isInstructor: boolean;

    dataSubscription: Subscription;
    assessment: Assessment;
    question: Question;

    showTask: boolean = false;
    promptExpanded: boolean = false;
    playbackEnded: boolean = false;

    constructor(
        private loaderService: LoaderService,
        private data: DataService,
        private modal: ModalController,
        private device: DeviceService,
        private http: HttpClient,
        public router: Router,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.loaderService.showLoading();
        if (!this.isInstructor) {
            this.dataSubscription = combineLatest([
                this.data.assessments.pipe(
                    map(list => list.find(item => item.Id === parseInt(this.assessmentId, 10)))
                ),
                this.data.questions.pipe(
                    map(list => list.find(item => item.Id === parseInt(this.questionId, 10)))
                )
            ]).subscribe(([assessment, question]) => {
                this.assessment = assessment
                this.question = question;

                if (!this.question.videoUrl && !this.question.audioUrl && !this.question.YoutubeVideoId) {
                    this.playbackEnded = true;
                } else {
                    if (!!!this.question.ReviewTime && this.question.ReplayMedia) {
                        this.playbackEnded = true;
                    }
                }
                this.loaderService.hideLoading();
            });
        } else {
            this.http.get<any>(`${environment.NApiDomain}/instructor/sync-rooms/question/${this.questionId}`).subscribe(res => {
                this.loaderService.hideLoading();
                if (!res.success) {
                    console.error('Error while getting data for question:', res.error);
                    return;
                }

                this.question = res.question;
            });
        }
    }

    expandPromptMedia(url: string, promptMedia: boolean, type: string) {
        if (this.promptExpanded) {
            return;
        }

        this.modal.create({
            component: ExpandMediaModalComponent,
            showBackdrop: false,
            cssClass: this.device.isOnMobile() ? 'mobile-prompt-modal' : 'prompt-media-modal',
            swipeToClose: true,
            componentProps: {
                mediaType: type,
                url: url,
                promptMedia: promptMedia,
                inRoom: this.inRoom

            }
        }).then(modal => {
            modal.present();
            this.promptExpanded = true;

            modal.onDidDismiss().then(() => {
                this.promptExpanded = false;
            });
        });
    }

    reportWSError(event: string) {
        // Catch a NaN value from the Custom Media Player error
        this.http.get<any>(`${environment.NApiDomain}/${this.authService.syncToken}/profile`).subscribe(studentData => {
            let reportErrorData = {
                UserName: studentData.UserName,
                FirstName: studentData.FirstName,
                LastName: studentData.LastName,
                ErrorMessage: event,
                Date: new Date(moment().valueOf()),
                Url: this.router.url,
                Email: 'devalerts@extemporeapp.com'
            };
            this.http.post<any>(`${environment.NApiDomain}/report-error`, reportErrorData).subscribe();
        });
    }

    ngOnDestroy() {
        this.loaderService.hideLoading();
        this.modal.getTop().then(openModal => {
            if (openModal) {
                openModal.dismiss();
           }
        });
    }
}
