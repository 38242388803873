import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-in-progress-component',
    templateUrl: './in-progress.component.html'
})

export class InProgressComponent {
    @Input('message') public message: string;
    @Input() dotSize: number;
    @Input() margin: number;
    dots = [1, 2, 3];

    constructor() { }
}
