export const STATIC_DATA = {
    EMAIL_REGEX: new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$'),
    TEXT_REGEX: new RegExp('^[a-zA-Z -]*$'),
    FULLNAME_REGEX: new RegExp('^([A-Z][a-z]{1,} )([A-Z][a-z]{1,} )?([A-Z][a-z]{1,})*$'),
    NAME_REGEX: new RegExp('^([A-Z][a-z]{1,})*$'),
    COLOR_REGEX: new RegExp('^(?:#(?:[A-Fa-f0-9]{3}){1,2}|(?:rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}|hsl[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*|(?:rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}|hsla[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*,)\s*0*(?:\.\d+|1(?:\.0*)?)\s*)[)])$'),
    DATE_REGEX: new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}'),
    USERNAME_REGEX: new RegExp('^\d*[a-zA-Z]([a-zA-Z0-9._])*$'),
    BLACKLIST_REGEX: new RegExp('^[^%<>^$()=+\/:;{}\\\]\[]+$'),
    ACRONYM_REGEX: new RegExp('^[a-zA-z-_]+$')
}
