import { Injectable } from "@angular/core";
import { Customer } from "app/admin/customer-data.service";

@Injectable()
export class AccountTypeService {

    constructor() { }

    getAccountType(customer: any): string {
        if (customer.OrganizationId) {
            if (customer.OrgStudentPaid) {
                return 'StudentPaid';
            } else if (customer.OrgTrial) {
                return 'Trial';
            } else if (customer.OrgConsumableLicenses) {
                return 'ConsumableLicenses';
            } else if (customer.OrgSubscriptionModel) {
                return 'SubscriptionModel';
            } else {
                // this is default if non of the above worked for old data
                return 'IP';
            }
        } else {
            if (customer.StudentPaid) {
                return 'StudentPaid';
            } else if (customer.Lite) {
                return 'Lite';
            } else if (customer.Trial) {
                return 'Trial';
            } else if (customer.ConsumableLicenses) {
                return 'ConsumableLicenses';
            } else if (customer.SubscriptionModel) {
                return 'SubscriptionModel';
            } else if (customer.IndividualEducator) {
                return 'IndividualEducator';
            } else {
                // this is default if non of the above worked for old data
                return 'IP';
            }
        }
    }

    getOrgAccountType(organization: any) {
          if (organization.Lite) {
            return 'Lite';
        } else if (organization.StudentPaid) {
            return 'StudentPaid';
        } else if (organization.Trial) {
            return 'Trial';
        } else if (organization.ConsumableLicenses) {
            return 'ConsumableLicenses';
        } else if (organization.SubscriptionModel) {
            return 'SubscriptionModel';
        } else {
            // this is default if non of the above worked for old data
            return 'IP';
        }
    }

    setAccountType(type: string, customer: Customer) {
        switch (type) {
            case 'StudentPaid': {
                customer.StudentPaid = true;
                customer.Trial = false;
                customer.Lite = false;
                customer.ConsumableLicenses = false;
                customer.SubscriptionModel = false;
                customer.IndividualEducator = false;
                break;
            }
            case 'Trial': {
                customer.StudentPaid = false;
                customer.Trial = true;
                customer.Lite = false;
                customer.ConsumableLicenses = false;
                customer.SubscriptionModel = false;
                customer.IndividualEducator = false;
                break;
            }
            case 'Lite': {
                customer.StudentPaid = false;
                customer.Trial = false;
                customer.Lite = true;
                customer.ConsumableLicenses = false;
                customer.SubscriptionModel = false;
                customer.IndividualEducator = false;
                break;
            }
            case 'ConsumableLicenses': {
                customer.StudentPaid = false;
                customer.Trial = false;
                customer.Lite = false;
                customer.ConsumableLicenses = true;
                customer.SubscriptionModel = false;
                customer.IndividualEducator = false;
                break;
            }
            case 'SubscriptionModel': {
                customer.StudentPaid = false;
                customer.Trial = false;
                customer.Lite = false;
                customer.ConsumableLicenses = false;
                customer.SubscriptionModel = true;
                customer.IndividualEducator = false;
                break;
            }
            case 'IndividualEducator': {
                customer.StudentPaid = false;
                customer.Trial = false;
                customer.Lite = false;
                customer.ConsumableLicenses = false;
                customer.SubscriptionModel = false;
                customer.IndividualEducator = true;
                break;
            }
            case 'IP': {
                customer.StudentPaid = false;
                customer.Trial = false;
                customer.Lite = false;
                customer.ConsumableLicenses = false;
                customer.SubscriptionModel = false;
                customer.IndividualEducator = false;
                break;
            }
        }
    }
}
