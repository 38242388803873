import { Injectable } from "@angular/core";
import { AlertController, ToastController } from "@ionic/angular";
import { environment } from "environments/environment";
import { DeviceService } from "./shared/device.service";
import { HTMLIonOverlayElement } from '@ionic/core';

@Injectable()

export class IonicAlertsService {
    constructor(private alert: AlertController, private toast: ToastController, private device: DeviceService ) { }

    createGenericAlert(header: string, message: string, subHeader?: string, dismiss?: boolean, handler?: any, additionalClass?: string) {
        this.alert.create({
            header: header,
            message: message,
            subHeader: subHeader,
            backdropDismiss: dismiss,
            cssClass: `whitespace-class ${additionalClass ? additionalClass : ''}`,
            buttons: [
                {text: 'Ok', handler: handler}
            ]
        }).then(alert => {
            alert.present();
            if (document.getElementById('feedbackAudio')) {
                document.getElementById('feedbackAudio').setAttribute('preload', 'auto');
                document.getElementById('feedbackAudio').setAttribute('controls', 'true');
                document.getElementById('feedbackAudio').setAttribute('style', 'width: 100%');
            }
        });
    }

    createGenericAlertWithPromise(header: string, message: string, subHeader?: string, dismiss?: boolean, handler?: any, additionalClass?: string): Promise<void> {
        return new Promise(resolve => {
            this.alert.create({
                header: header,
                message: message,
                subHeader: subHeader,
                backdropDismiss: dismiss,
                cssClass: `whitespace-class ${additionalClass ? additionalClass : ''}`,
                buttons: [
                    {text: 'Ok', handler: () => {
                        resolve();
                    }}
                ]
            }).then(alert => {
                alert.present();
            });
        });
    }

    createConfirmAlert(header: string, message: string, handler: any, cssClass?: any) {
        this.alert.create({
            header: header,
            message: message,
            buttons: [
                {text: 'Cancel', role: 'cancel'},
                {text: 'Ok', handler: handler}
            ],
            cssClass: cssClass
        }).then(alert => {
            alert.present();
        });
    }

    createConfirmRubricAlert(header: string, message: string, handler: any, cssClass?: any, submitted?: boolean) {
        if (!submitted) {
            this.alert.create({
                header: header,
                message: message,
                buttons: [
                    {text: 'Close', role: 'cancel'},
                    {text: 'Start Question', handler: handler}
                ],
                cssClass: cssClass
            }).then(alert => {
                alert.present();
            });
        } else {
            this.alert.create({
                header: header,
                message: message,
                buttons: [
                    {text: 'Close', role: 'cancel'}
                ],
                cssClass: cssClass
            }).then(alert => {
                alert.present();
            });
        }
    }

    createConfirmAlertWithPromise(header: string, message: string, cssClass?: any): Promise<boolean> {
        return new Promise(resolve => {
            this.alert.create({
                header: header,
                message: message,
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            resolve(false);
                        }
                    },
                    {
                        text: 'Ok',
                        handler: () => {
                            resolve(true);
                        }
                    }
                ],
                cssClass: cssClass
            }).then(alert => {
                alert.present();
            });
        });
    }

    createEmailCheckAlert(userEmail: string): Promise<HTMLIonAlertElement> {
        const resetPassLink = environment.CMSDomain + '/Account/ResetPassword';
        const resetUsernameLink = environment.CMSDomain + '/Account/ForgotUsername';
        const alertMessage = `A user with the email <strong>${userEmail}</strong> already exists <br/>
            <a href="${resetPassLink}">Forgot Password?</a> <br/>
            <a href="${resetUsernameLink}">Forgot Username?</a>`;

        return this.alert.create({
            header: 'Email in use',
            message: alertMessage,
            backdropDismiss: false,
            inputs: [
                {type:'checkbox', name: 'doNotDisplay', label: 'Do not display again'}
            ],
            buttons: [
                {text: 'Cancel', role: 'cancel'},
                {text: 'Continue Anyway'}
            ]
        });
    }

    createToast(message: string, header?:string) {
        this.toast.create({
            header: header,
            message: message,
            color: 'danger',
            position: this.device.isOnMobile() ? 'bottom' : 'top',
            duration: 5000,
        }).then(toast => {
            toast.present();
        });
    }

    closeAll() {
        // adjust selector to fit your needs
        const overlays = document.querySelectorAll('ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast');
        const overlaysArr = Array.from(overlays) as HTMLIonOverlayElement[];
        overlaysArr.forEach(o => o.dismiss());
    };
}
