import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { DeviceService } from "./device.service";
import { LoaderService } from "./loader.service";
import { YouTubePlayer } from '@angular/youtube-player';
import { takeWhile } from "rxjs/operators";

import * as WaveSurfer from 'wavesurfer.js';

@Component({
    selector: 'custom-media-player',
    templateUrl: './custom-media-player.component.html',
    styleUrls: ['../instructor/gradebook/gradebook-styles.scss', './custom-media-player.component.scss']
})
export class CustomMediaPlayerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input('from') from: 'instructor' | 'student';
    @Input('type') type: string;
    @Input('youtubeId') youtubeId: string;
    @Input('ytStartTime') ytStartTime: number;
    @Input('ytEndTime') ytEndTime: number;
    @Input('waveContainerName') waveContainerName: string;
    @Input('src') src: string;
    @Input('mini') mini: boolean;
    @Input('blobsDuration') blobsDuration: any;
    @Input('blockUserInteraction') blockUserInteraction: boolean;
    @Input('answerPrompt') answerPrompt: boolean;
    @Input('play') play: BehaviorSubject<number>;
    @Input('stop') stop: boolean;
    @Input('no-skip') noSkip: boolean;
    @Input('orderNumber') orderNumber: number;
    @Input('playSuccessively') playSuccessively: boolean;
    @Input('replayMedia') replayMedia: boolean;
    @Input('dontAllowDownload') dontAllowDownload: boolean;
    @Input('enablePlaybackControls') enablePlaybackControls: boolean = true;
    @Input('APmode') APMode: boolean;
    @Input('shouldMirror') shouldMirror: boolean = true;
    @Input('playMedia') playMedia: BehaviorSubject<boolean>;
    @Input('exitFullScreenEmitter') exitFullScreenEmitter: BehaviorSubject<boolean>;
    @Output('ended') ended: EventEmitter<void> = new EventEmitter<void>();
    @Output('play-pause') playPause: EventEmitter<void> = new EventEmitter<void>();
    @Output('seekedTo') seekedTo: EventEmitter<number> = new EventEmitter<number>();
    @Output('ws-error') ws_error: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('media') mediaElementRef: ElementRef;
    @ViewChild(YouTubePlayer, { static: false }) youtubeVideo: YouTubePlayer;
    media: HTMLVideoElement | HTMLAudioElement;
    mediaPlaying: boolean = false;
    seekBarValue: number = 0;
    currentTime: number = 0;
    duration: number;
    currentPlaybackSpeed: number;
    disableOptions: boolean = false;
    showDuration: boolean;
    playbackOptionsValues = [0.75, 1, 1.25, 1.5, 1.75, 2];
    firstPlaythrough: boolean = true;
    currentMCMedia: HTMLElement;
    waveCreated = false;

    wavesurfer;

    controlsObject = {
        controls: 0,
        disablekb: 1,
        modestbranding: 1,
        playsinline: 1,
    };
    ytVideoReady: boolean = false;
    ytVideoState: number;
    ytTimerSubscriber: Subscription;

    constructor(
        private deviceService: DeviceService,
        private loaderService: LoaderService,
        private zone: NgZone
    ) { }

    ngOnChanges() {
        if (!this.stop) {
            this.seekBarValue = 0;
            this.currentTime = 0;
            this.seekBarValue = 0;
            this.mediaPlaying = false;

            if (this.youtubeVideo) {
                this.youtubeVideo.pauseVideo();
                this.youtubeVideo.seekTo(0, true);
            }
            this.media = null;
        }

        if (this.wavesurfer) {
            this.wavesurfer.destroy();
            this.wavesurfer = null;
            this.waveCreated = false;
        }

        if (this.waveContainerName) {
            this.createWave();
        } else {
            this.setPlaybackSpeed();
        }

        if (this.answerPrompt) {
            this.disableOptions = this.blockUserInteraction;
            this.currentMCMedia = document.getElementById('mediaChoice' + this.orderNumber);
        } else {
            if (this.stop) {
                this.disableOptions = true;
                if (this.mediaPlaying || (this.wavesurfer && this.wavesurfer.isPlaying())) {
                    // Stop the playback of the media and reset the player
                    this.stopMediaPlayback();
                }
            }
        }

        setTimeout(() => {
            if (!this.waveContainerName && this.mediaElementRef) {
                this.loadMedia();
            }
        });
    }

    ngOnInit() {
        const tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
    }

    ngAfterViewInit() {
        if (this.blockUserInteraction && this.answerPrompt) {
            setTimeout(() => {
                this.disableOptions = true;
            }, 0);
        }

        if (this.playSuccessively && this.play) {
            this.play.subscribe(res => {
                if (this.orderNumber === res) {
                    if (this.media) {
                        this.media.play();
                        this.mediaPlaying = true;
                    }
                }
            });
        }

        if (this.APMode && this.playMedia) {
            this.playMedia.subscribe(res => {
                setTimeout(() => {
                    if (res && this.media) {
                        this.media.play();
                        this.mediaPlaying = true;
                        this.playPause.emit();
                    }
                }, 0);
            });
        }

        if (this.exitFullScreenEmitter) {
            this.exitFullScreenEmitter.subscribe(res => {
                if (res) {
                    this.exitFullScreen();
                }
            });
        }
    }

    loadMedia() {
        if (!this.mediaElementRef) {
            return;
        }

        this.media = this.mediaElementRef.nativeElement;
        this.media.muted = false;
        this.media.playbackRate = this.playbackOptionsValues[this.currentPlaybackSpeed];

        this.media.onloadedmetadata = () => {
            this.onMediaLoaded();
        };

        // End the playback of the previous media and reset the player
        this.endPlayback();
    }

    setPlaybackSpeed() {
        if (this.mini || this.from === 'student') {
            this.currentPlaybackSpeed = 1;
        } else {
            if (!this.currentPlaybackSpeed) {
                let mediaPlaybackSpeed = localStorage.getItem('mediaPlaybackSpeed');
                let mediaPlaybackSpeedIndex = localStorage.getItem('mediaPlaybackSpeedIndex') ? parseInt(localStorage.getItem('mediaPlaybackSpeedIndex')) : 1;

                if (mediaPlaybackSpeed) {
                    let foundMediaPlaybackSpeedIndex = this.playbackOptionsValues.findIndex(x => x === parseFloat(mediaPlaybackSpeed));
                    if (foundMediaPlaybackSpeedIndex !== -1) {
                        mediaPlaybackSpeedIndex = foundMediaPlaybackSpeedIndex;
                    }
                    localStorage.removeItem('mediaPlaybackSpeed');
                }

                this.currentPlaybackSpeed = mediaPlaybackSpeedIndex;
                if (this.wavesurfer) {
                    this.wavesurfer.setPlaybackRate(this.playbackOptionsValues[this.currentPlaybackSpeed]);
                }
            } else {
                if (this.wavesurfer) {
                    this.wavesurfer.setPlaybackRate(this.playbackOptionsValues[this.currentPlaybackSpeed]);
                }
            }
        }
    }

    onMediaLoaded() {
        /**
         * Bypassing an issue with chrome where duration is Infinity on a blob
         * so we get the duration from the recording and send it over
         */
        if (this.youtubeId) {
            this.showDuration = false;
        } else if (this.blobsDuration) {
            this.duration = this.blobsDuration as number;
            this.showDuration = true;
        } else {
            if (isFinite(this.media.duration)) {
                this.duration = Math.round(this.media.duration);
                this.showDuration = true;
            } else {
                this.duration = 30;
                this.showDuration = false;
            }
        }

        this.media.addEventListener('timeupdate', () => {
            this.currentTime = Math.round(this.media.currentTime);
            this.seekBarValue = this.duration ? Math.round(this.currentTime / this.duration * 100) : 0;

            if (isFinite(this.media.duration) && this.media.currentTime >= this.media.duration) {
                this.ended.next();
                this.stopMediaPlayback();

                if (this.blockUserInteraction && !this.answerPrompt) {
                    if (this.replayMedia) {
                        this.disableOptions = false;
                    }
                }
            }
        });

        this.media.addEventListener('ended', () => {
            this.endPlayback();
            if (this.blockUserInteraction && !this.answerPrompt) {
                if (this.replayMedia) {
                    this.disableOptions = false;
                }
            }
        });

        this.media.addEventListener('play', () => {
            this.mediaPlaying = true;

            if (this.wavesurfer) {
                this.wavesurfer.play();
            }

            if (this.blockUserInteraction && !this.answerPrompt) {
                if (this.firstPlaythrough) {
                    this.disableOptions = true;
                    this.firstPlaythrough = false;
                }
            }

            if (this.answerPrompt) {
                this.currentMCMedia = document.getElementById('mediaChoice' + this.orderNumber);
                this.currentMCMedia.setAttribute('style', 'border-style: solid; border-color: var(--light-main)');
            }
        });

        this.media.addEventListener('pause', () => {
            this.mediaPlaying = false;

            if (this.wavesurfer) {
                this.wavesurfer.pause();
            }
        });
    }

    createWave() {
        if (this.waveCreated) {
            return;
        }

        // We need setTimeout because ngOnChanges is called before DOM initialization and we never find the wave container then
        setTimeout(() => {
            let waveContainerElement = document.getElementById(this.waveContainerName);
            if (!waveContainerElement) {
                return;
            }

            this.waveCreated = true;
            this.loaderService.showLoading();

            this.wavesurfer = WaveSurfer.create({
                backend: 'MediaElement',
                container: `#${this.waveContainerName}`,
                waveColor: '#152942',
                progressColor: '#0f6396',
                height: 75,
                hideScrollbar: true,
                interact: true,
                responsive: true,
                barHeight: 1.5
            });

            this.wavesurfer.load(this.src);
            this.setPlaybackSpeed();

            // All of the javascript events for wavesurfer are ran in an angular zone because so change detection can run smoothly
            this.wavesurfer.on('error', (error) => {
                console.log('Wavesurfer error', error);
                this.zone.run(() => {
                    let waveContainer = document.getElementById('wave-container');
                    if (waveContainer) {
                        waveContainer.remove();
                    }
                    this.waveContainerName = null;
                    setTimeout(() => {
                        if (this.mediaElementRef) {
                            this.loadMedia();
                        }
                    });
                    this.loaderService.hideLoading();
                });
            });

            this.wavesurfer.on('ready', () => {
                this.zone.run(() => {
                    this.duration = Math.round(this.wavesurfer.getDuration());
                    this.endPlayback();
                    this.loaderService.hideLoading();
                });
            });

            this.wavesurfer.on('audioprocess', () => {
                this.zone.run(() => {
                    this.currentTime = Math.round(isNaN(this.wavesurfer.getCurrentTime()) ? this.reportIncorrectState(this.wavesurfer.getCurrentTime()) : this.wavesurfer.getCurrentTime());
                });
            });

            this.wavesurfer.on('seek', () => {
                this.zone.run(() => {
                    this.currentTime = Math.round(this.wavesurfer.getCurrentTime());
                });
            });

            this.wavesurfer.on('finish', () => {
                this.zone.run(() => {
                    this.endPlayback();
                });
            });
        }, 0);
    }

    stopMediaPlayback() {
        if (this.wavesurfer) {
            this.wavesurfer.stop();
        } else if (this.youtubeId) {
            this.mediaPlaying = false;
            this.seekBarValue = 0;
            this.youtubeVideo.pauseVideo();
            this.youtubeVideo.seekTo(this.ytStartTime, true);
        } else {
            this.media.currentTime = 0;
            this.media.pause();

            if ((this.type === 'video' || this.type?.includes('screen')) && this.mini && (document as any).pictureInPictureElement) {
                (document as any).exitPictureInPicture();
            }
        }

        this.currentTime = 0;
        this.mediaPlaying = false;
    }

    endPlayback() {
        this.mediaPlaying = false;
        if (!this.playSuccessively) {
            this.ended.next();
        }

        if (this.wavesurfer) {
            this.wavesurfer.seekTo(0);
            this.currentTime = 0;
        } else if (this.youtubeId) {
            this.currentTime = 0;
            this.seekBarValue = 0;
            this.youtubeVideo.pauseVideo();
            this.youtubeVideo.seekTo(this.ytStartTime, true);
        } else if (this.media){
            this.currentTime = Math.round(this.media.currentTime);
            this.media.pause();
        }

        if (this.answerPrompt && this.currentMCMedia && this.currentMCMedia.hasAttribute('style')) {
            this.currentMCMedia.removeAttribute('style');
        }

        if ((this.type === 'video' || this.type?.includes('screen')) && this.mini && (document as any).pictureInPictureElement) {
            (document as any).exitPictureInPicture();
        }

        // Check if the cached media source is different than the current one AND it's not a blob
        if (!this.blobsDuration && this.media && this.media.src !== this.src) {
            this.media.src = this.src;
        }
    }

    playPausePlayer() {
        if (!this.disableOptions) {
            this.playPause.next();
            if (this.youtubeVideo) {
                if (this.mediaPlaying) {
                    this.youtubeVideo.pauseVideo();
                } else {
                    this.youtubeVideo.playVideo();
                }

            } else if (this.media) {
                let tempVideoElement = this.media as any;

                if (this.mediaPlaying) {
                    if ((this.type === 'video' || this.type?.includes('screen')) && this.mini && (document as any).pictureInPictureElement) {
                        (document as any).exitPictureInPicture();
                    }
                    if (this.answerPrompt && this.currentMCMedia && this.currentMCMedia.hasAttribute('style')) {
                        this.currentMCMedia.removeAttribute('style');
                    }
                    this.media.pause();
                } else {
                    if ((this.type === 'video' || this.type?.includes('screen')) && this.mini && (document as any).pictureInPictureEnabled) {
                        tempVideoElement.requestPictureInPicture();
                    }
                    if (this.answerPrompt) {
                        this.currentMCMedia = document.getElementById('mediaChoice' + this.orderNumber);
                        this.currentMCMedia.setAttribute('style', 'border-style: solid; border-color: var(--light-main)');
                    }
                    this.media.play();
                }
            } else if (this.wavesurfer) {
                this.wavesurfer.playPause();
            }

            this.mediaPlaying = !this.mediaPlaying;
        }
    }

    changeSpeed(direction: string) {
        if (direction === 'up') {
            if (this.currentPlaybackSpeed === this.playbackOptionsValues.length - 1) {
                return;
            }

            this.currentPlaybackSpeed++;
        } else {
            if (this.currentPlaybackSpeed === 0) {
                return;
            }

            this.currentPlaybackSpeed--;
        }

        if (this.youtubeVideo) {
            this.youtubeVideo.setPlaybackRate(this.playbackOptionsValues[this.currentPlaybackSpeed]);
        } else if (this.media) {
            this.media.playbackRate = this.playbackOptionsValues[this.currentPlaybackSpeed];
        } else if (this.wavesurfer) {
            this.wavesurfer.setPlaybackRate(this.playbackOptionsValues[this.currentPlaybackSpeed]);
        }

        localStorage.setItem('mediaPlaybackSpeedIndex', this.currentPlaybackSpeed.toString());
    }

    back(seconds: number) {
        if (!this.disableOptions && !this.noSkip) {
            if (this.youtubeVideo) {
                this.currentTime = this.currentTime - seconds;
                if (this.currentTime < 0) {
                    this.currentTime = 0;
                }
                this.seekBarValue = Math.round(this.currentTime / this.duration * 100);
                this.youtubeVideo.seekTo(this.currentTime + this.ytStartTime, true);
            } else if (this.media) {
                this.media.currentTime -= seconds;
                this.currentTime = Math.round(this.media.currentTime);
            } else if (this.wavesurfer) {
                if (this.currentTime - seconds < 0) {
                    this.wavesurfer.seekTo(0);
                } else {
                    this.wavesurfer.seekTo((this.currentTime - seconds) / this.duration);
                }
                this.currentTime = Math.round(this.wavesurfer.getCurrentTime());
            }
        }
    }

    forward(seconds: number) {
        if (!this.disableOptions && !this.noSkip) {
            if (this.youtubeVideo) {
                this.currentTime += seconds;
                if (this.currentTime + this.ytStartTime > this.ytEndTime) {
                    this.currentTime = 0;
                }
                this.seekBarValue = Math.round(this.currentTime / this.duration * 100);
                this.youtubeVideo.seekTo(this.currentTime + this.ytStartTime, true);
            } else if (this.media) {
                this.media.currentTime += seconds;
                this.currentTime = Math.round(this.media.currentTime);
            } else if (this.wavesurfer) {
                if (this.currentTime + seconds > this.duration) {
                    this.wavesurfer.seekTo(1);
                } else {
                    this.wavesurfer.seekTo((this.currentTime + seconds) / this.duration);
                }
                this.currentTime = Math.round(this.wavesurfer.getCurrentTime());
            }
        }
    }

    fullScreen() {
        if (this.media) {
            if (!this.deviceService.isIos()) {
                this.media.requestFullscreen();
            } else {
                (document as any).webkitEnterFullScreen();
            }
        }
    }

    exitFullScreen() {
        if (this.media) {
            if (!this.deviceService.isIos() && document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
    }

    openPictureInPicture() {
        if (this.media) {
            let tempVideoElement = this.media as any;
            tempVideoElement.requestPictureInPicture();
        }
    }

    seekBarValueChanged() {
        if (this.youtubeVideo) {
            this.currentTime = Math.round(this.duration * this.seekBarValue / 100);
            this.youtubeVideo.seekTo(this.currentTime + this.ytStartTime, true);
        } else if (this.media) {
            this.media.currentTime = this.duration * this.seekBarValue / 100;
            this.currentTime = Math.round(this.media.currentTime);
        }
    }

    youtubeVideoReady() {
        this.ytVideoReady = true;
    }

    playYoutubeVideo() {
        this.playPause.next();
        this.youtubeVideo.playVideo();
        this.mediaPlaying = true;
    }

    youtubeVideoControls(event) {
        /**
         * -1 - unstarted
         * 0 - ended
         * 1 - playing
         * 2 - paused
         * 3 - buffering
         * 5 - video cued
         */
        if (event) {
            this.ytVideoState = event.data;
            if (event.data === 1) {
                this.showDuration = true;
                this.mediaPlaying = true;
                this.ytStartTime = this.ytStartTime ? this.ytStartTime : 0;
                this.ytEndTime = this.ytEndTime ? this.ytEndTime : Math.ceil(this.youtubeVideo.getDuration());
                this.duration = this.ytEndTime - this.ytStartTime;

                if (this.blockUserInteraction) {
                    if (this.firstPlaythrough) {
                        this.disableOptions = true;
                        this.firstPlaythrough = false;
                    }
                }

                /**
                 * Youtube doesn't have an event that updates us on the elapsed time from the video
                 * so we check every second while the video hasn't ended and it's still in playing mode
                 */
                this.ytTimerSubscriber = timer(0, 1000)
                .pipe(takeWhile(() => this.currentTime <= this.duration && this.ytVideoState === 1))
                .subscribe(() => {
                    this.currentTime = Math.ceil(this.youtubeVideo.getCurrentTime() - this.ytStartTime);
                    this.seekBarValue = Math.round(this.currentTime / this.duration * 100);
                });
            } else if (event.data === 0) {
                if (this.blockUserInteraction) {
                    if (this.replayMedia) {
                        this.disableOptions = false;
                    }
                }

                this.mediaPlaying = false;
                this.currentTime = 0;
                this.seekBarValue = 0;
                this.youtubeVideo.pauseVideo();
                this.youtubeVideo.seekTo(this.ytStartTime, true);
                this.ended.next();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.wavesurfer) {
            this.wavesurfer.destroy();
        }

        if (this.ytTimerSubscriber) {
            this.ytTimerSubscriber.unsubscribe();
        }

        if ((document as any).pictureInPictureElement) {
            (document as any).exitPictureInPicture();
        }
    }

    reportIncorrectState(currentTime: string) {
        this.ws_error.next('WaveSurefer error occured!!! NaN value for current position was received, position value: ' + currentTime);
    }
}
