import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { DeviceService } from './device.service';

@Component({
    selector: 'marketing-footer',
    templateUrl: './marketing-footer.component.html',
})
export class MarketingFooterComponent {
    environment = environment;

    constructor(public deviceService: DeviceService) {}
}
