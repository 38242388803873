import {
    Component, OnInit, OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'attempt-overlay',
    templateUrl: './attempt-overlay.component.html',
})
export class AttemptOverlayComponent implements OnInit, OnDestroy {
    i: number = 3;
    timer: Subscription;

    constructor() { }

    ngOnInit() {
        const observableTimer = interval(1000).pipe(take(3));

        this.timer = observableTimer.subscribe(() => {
            this.i--;
        });
    }

    ngOnDestroy() {
        if (this.timer) {
            this.timer.unsubscribe();
        }
    }
}
