import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { LoaderService } from './loader.service';

@Component({
    templateUrl: './mat-teacher-sso.component.html',
})
export class MatTeacherSsoComponent {
    isOrganization: boolean = false;
    role: string = 'initial';
    orgAcronym: string;
    error: string;

    constructor(
        public dialogRef: MatDialogRef<MatTeacherSsoComponent>,
        private http: HttpClient,
        private loaderService: LoaderService
    ) { }

    done() {
        this.loaderService.showLoading();
        this.error = null;

        if (this.role === 'initial') {
            this.error = 'Please choose a role';
            this.loaderService.hideLoading();
            return;
        }

        if (this.isOrganization) {
            this.orgAcronym = this.orgAcronym ? this.orgAcronym.trim() : null;

            if (!this.orgAcronym) {
                this.error = 'Please input acronym if you are a part of an organization';
                this.loaderService.hideLoading();
                return;
            }

            this.http.get<any>(`${environment.NApiDomain}/sso/check-org/${this.orgAcronym}`).subscribe(res => {
                if (!res.success) {
                    this.error = 'We experienced an error. Please try logging in again';
                    this.loaderService.hideLoading();
                    return;
                }

                if (!res.orgFound) {
                    this.error = 'We couldn\'t find an organization with that acronym. Please check again';
                    this.loaderService.hideLoading();
                    return;
                }

                this.loaderService.hideLoading();
                this.dialogRef.close({
                    isOranization: this.isOrganization,
                    role: this.role,
                    orgId: res.orgId,
                    orgName: res.orgName
                });
            });
        } else {
            this.dialogRef.close({
                isOranization: this.isOrganization,
                role: this.role
            });
        }
    }
}
