import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { DeviceService } from './device.service';

declare var Stripe;

@Component({
    selector: 'app-stripe-payment',
    templateUrl: './stripe-payment.component.html'
})
export class StripePaymentComponent implements AfterViewInit, OnDestroy {
    @ViewChild('cardInfo') cardInfo: ElementRef;
    @ViewChild('cardNumber') cardNumber: ElementRef;
    @ViewChild('expiryDate') expiryDate: ElementRef;
    @ViewChild('cvcNumber') cvcNumber: ElementRef;
    totalAmount: number;
    card: any;

    creditCardNumber: any;
    creditCardExpiry: any;
    creditCardCvc: any;

    emailValid: boolean;
    email: string;
    cardHandler = this.onChange.bind(this);
    cardError: string;
    stripe: any;

    constructor(
        private cd: ChangeDetectorRef,
        private dialogRef: MatDialogRef<StripePaymentComponent>,
        public deviceService: DeviceService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.totalAmount = data['totalAmount'];
    }

    ngAfterViewInit() {
        this.stripe = Stripe(environment.stripeKey);
        const elements = this.stripe.elements();
        const cardStyle = {
            base: {
                iconColor: '#666EE8',
                lineHeight: '40px',
                fontWeight: 300,
                fontSize: '17px',

                '::placeholder': {
                    color: '#CFD7E0',
                },
            },
        }

        if (this.deviceService.isOnMobile()) {
            this.creditCardNumber = elements.create('cardNumber', {
                style: cardStyle,
                placeholder: 'Credit Card Number'
            });
            this.creditCardNumber.mount(this.cardNumber.nativeElement);
            this.creditCardNumber.addEventListener('change', this.cardHandler);

            this.creditCardNumber.addEventListener('change', ({ error }) => {
                this.cardError = error && error.message;
            });

            this.creditCardExpiry = elements.create('cardExpiry', {
                style: cardStyle,
                placeholder: 'Expiration Date'
            });
            this.creditCardExpiry.mount(this.expiryDate.nativeElement);
            this.creditCardExpiry.addEventListener('change', this.cardHandler);

            this.creditCardExpiry.addEventListener('change', ({ error }) => {
                this.cardError = error && error.message;
            });

            this.creditCardCvc = elements.create('cardCvc', {
                style: cardStyle,
                placeholder: 'CVC'
            });
            this.creditCardCvc.mount(this.cvcNumber.nativeElement);
            this.creditCardCvc.addEventListener('change', this.cardHandler);

            this.creditCardCvc.addEventListener('change', ({ error }) => {
                this.cardError = error && error.message;
            });
        } else {
            this.card = elements.create('card', {
                style: cardStyle,
                placeholder: 'Card Number'
            });
            this.card.mount(this.cardInfo.nativeElement);
            this.card.addEventListener('change', this.cardHandler);

            this.card.addEventListener('change', ({ error }) => {
                this.cardError = error && error.message;
            });
        }
    }

    checkEmail(email: string) {
        if (!email || email.length === 0) {
            this.emailValid = false;
            this.cardError = 'Email is required!';
        } else {
            if (!email.match(/\S+@\S+\.\S+/)) {
                this.cardError = 'Please provide a valid e-mail address.';
                this.emailValid = false;
            } else {
                this.cardError = null;
                this.emailValid = true;
            }
        }
    }

    onChange({ error }) {
        if (error) {
            this.cardError = error.message;
        } else {
            this.cardError = null;
        }
        this.cd.detectChanges();
    }

    handleForm(e) {
        e.preventDefault();
        this.stripe.createSource(this.deviceService.isOnMobile() ? this.creditCardNumber : this.card).then(result => {
            if (result.error) {
                this.onError(result.error);
            } else {
                this.onSuccess(result.source.id);
            }
        });
    }

    onSuccess(token) {
        if (this.data.email) {
            this.dialogRef.close({ email: this.email, token: token });
        } else {
            this.dialogRef.close({ token });
        }
    }

    onError(error) {
        console.log(error);
        if (error.message) {
            this.cardError = error.message;
        }
    }

    cancelPayment() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        // We remove event listener here to keep memory clean
        if (this.deviceService.isOnMobile()) {
            this.creditCardNumber.removeEventListener('change', this.cardHandler);
            this.creditCardNumber.destroy();
            this.creditCardCvc.removeEventListener('change', this.cardHandler);
            this.creditCardCvc.destroy();
            this.creditCardExpiry.removeEventListener('change', this.cardHandler);
            this.creditCardExpiry.destroy();
        } else {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    }
}
