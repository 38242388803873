import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { DeviceService } from "app/shared/device.service";

@Component({
    selector: 'expand-media-modal',
    templateUrl: './expand-media-modal.component.html'
})

export class ExpandMediaModalComponent {
    type: string;
    url: string;
    choice: string;
    safeMode: boolean;
    promptMedia: boolean;
    inRoom: boolean = false;
    constructor(private params: NavParams, private modal: ModalController, public device: DeviceService) {
        if (this.params) {
            this.type = this.params.get('mediaType');
            this.url = this.params.get('url');
            this.choice = this.params.get('content');
            this.safeMode = this.params.get('safeMode');
            this.promptMedia = this.params.get('promptMedia');
            this.inRoom = this.params.get('inRoom');
        }
    }

    onRightClick() {
        return this.safeMode ? false : true;
    }

    closeModal() {
        this.modal.dismiss();
    }
}
