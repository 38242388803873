import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';

export class TwilioParticipantModel {
    displayName: string;
    sid: string;
    audioTrackSid: string;
    videoTrackSid: string;
    remoteDiv: string;
    remoteName: string;
    divsCreated: boolean;
    muted: boolean;
    noCamera: boolean;
}

@Injectable()
export class TwilioService {
    room: any;
    canActivateSyncRoom: boolean = false;

    constructor(
        private http: HttpClient
    ) { }

    getMediaFile(roomId): Observable<any> {
        return this.http.get<any>(`${environment.NApiDomain}/twilio/get-media-resource/${roomId}`);
    }

    getNameForCompositionStatus(id: number): string {
        switch(id) {
            case 1: {
                return 'enqueued';
            }
            case 2: {
                return 'started';
            }
            case 3: {
                return 'available';
            }
            case 4: {
                return 'failed';
            }
            case 5: {
                return 'failed-Retry';
            }
        }
    }

    isRoomCompleted(endTime: any, roomEnded: string, compositionSid: string) {
       if (roomEnded) {
            // we set roomEnded when we get event from room callback
            return true;
        } else if (compositionSid) {
             // if we have compositionSid the room is always completed
            return true;
        } else if (endTime < moment().valueOf()){
            return true;
        } else {
            return false;
        }
    }

    async testMicrophoneLevel(track, onLevelChanged) {
        const AudioContext = window.AudioContext;
        const audioContext = AudioContext ? new AudioContext() : null;

        if (!audioContext) {
            return;
        }

        await audioContext.resume();

        // Create an analyser to access the raw audio samples from the microphone.
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 1024;
        analyser.smoothingTimeConstant = 0.5;

        // Connect the LocalAudioTrack's media source to the analyser.
        const stream = new MediaStream([track.mediaStreamTrack]);
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        const samples = new Uint8Array(analyser.frequencyBinCount);
        let level = null;

        // Periodically calculate the audio level from the captured samples,
        // and if changed, call the callback with the new audio level.
        requestAnimationFrame(function checkLevel() {
            analyser.getByteFrequencyData(samples);
            const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0);
            const rms = Math.sqrt(sumSq / samples.length);
            const log2Rms = rms && Math.log2(rms);

            // Audio level ranges from 0 (silence) to 10 (loudest).
            const newLevel = Math.ceil(10 * log2Rms / 8);
            if (level !== newLevel) {
                level = newLevel;
                onLevelChanged(level);
            }

            // Continue calculating the level only if the audio track is live.
            if (track.mediaStreamTrack.readyState === 'live') {
                requestAnimationFrame(checkLevel);
            } else {
                requestAnimationFrame(() => onLevelChanged(0));
            }
        });
    }
}
