import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class CurrentModuleService {

    constructor(public router: Router, public route: ActivatedRoute) { }

    getCurrentModule(): Observable<string> {
        return this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .pipe(map(({ urlAfterRedirects }: NavigationEnd) => {
                if (
                    (urlAfterRedirects.includes('/admin') ||
                    urlAfterRedirects.includes('rubric/global')) &&
                    !urlAfterRedirects.includes('gradebook')
                ) {
                    return 'admin';
                } else if (urlAfterRedirects.includes('/demo') ||
                    urlAfterRedirects.includes('/instructor/add')) {
                    // In this module there is no toolbar
                    return 'demo';
                } else if (urlAfterRedirects.includes('/new') &&
                    !urlAfterRedirects.includes('/n/new')) {
                    return 'instructorModuleNew';
                } else if (urlAfterRedirects.includes('gradebook') ||
                    urlAfterRedirects.includes('commons') ||
                    urlAfterRedirects.includes('rubric') ||
                    urlAfterRedirects.includes('district-reports') ||
                    urlAfterRedirects.includes('ask-tempo')
                ) {
                    return 'gradebookModule';
                } else if (
                    (
                        urlAfterRedirects.includes('/instructor') ||
                        urlAfterRedirects.includes('/upgrade')
                    ) &&
                    !urlAfterRedirects.includes('/add') &&
                    !urlAfterRedirects.includes('/login') &&
                    !urlAfterRedirects.includes('/commons')
                ) {
                    return 'instructorModule';
                } else if (urlAfterRedirects.includes('/n') ||
                    urlAfterRedirects.includes('/guest-portfolio') ||
                    urlAfterRedirects.includes('/instructor/login') ||
                    urlAfterRedirects.includes('/google-classroom') ||
                    urlAfterRedirects.includes('/clever') ||
                    urlAfterRedirects.includes('class-link') ||
                    urlAfterRedirects.includes('edlink') ||
                    urlAfterRedirects === '/'
                ) {
                    return 'none';
                } else {
                    return 'studentModule';
                }
            }));

            // urlAfterRedirects.includes('/instructor/add'))
    }

    getModuleForRoute(route: string): string {
        if (
            (route.includes('/admin') ||
            route.includes('rubric/global')) &&
            !route.includes('gradebook')
        ) {
            return 'admin';
        } else if (route.includes('/demo') ||
            route.includes('/instructor/add')) {
            // In this module there is no toolbar
            return 'demo';
        } else if (route.includes('/new') &&
            !route.includes('/n/new')) {
            return 'instructorModuleNew';
        } else if (route.includes('gradebook') ||
            route.includes('commons') ||
            route.includes('rubric') ||
            route.includes('manage-users')
        ) {
            return 'gradebookModule';
        } else if (
            (
                route.includes('/instructor') ||
                route.includes('/upgrade')
            ) &&
            !route.includes('/add') &&
            !route.includes('/login') &&
            !route.includes('/commons')
        ) {
            return 'instructorModule';
        } else if (route.includes('/n') ||
            route.includes('/guest-portfolio') ||
            route.includes('/instructor/login') ||
            route.includes('/google-classroom') ||
            route.includes('/clever') ||
            route.includes('class-link') ||
            route.includes('edlink') ||
            route === '/'
        ) {
            return 'none';
        } else {
            return 'studentModule';
        }
    }
}
