import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { CurrentModuleService } from 'app/current-module.service';
import { filter } from 'rxjs/operators';
import { AdminAuthService } from 'app/admin/admin-auth.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { IonicAlertsService } from 'app/ionic-alerts.service';

@Injectable()
export class UserInactivityService {
    inactivityBehaviorSubject = new BehaviorSubject<boolean>(false);
    inAttempt: boolean;
    forceExit: boolean;
    inactivityPeriod = 0;

    constructor(
        private authService: AuthService,
        private router: Router,
        private currentModuleService: CurrentModuleService,
        private adminAuthService: AdminAuthService,
        private http: HttpClient,
        private cookieService: CookieService,
        private alertService: IonicAlertsService
    ) {
        let lastModule;
        this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
            let currentModule = this.currentModuleService.getModuleForRoute(urlAfterRedirects);
            if (currentModule != lastModule) {
                lastModule = currentModule;

                if (currentModule === 'gradebookModule' || currentModule === 'instructorModule') {
                    let token = this.cookieService.get('ext-user');
                    if (!token) {
                        return;
                    }
                    this.getInactivityPeriodFromDB(token);
                } else if (currentModule === 'studentModule') {
                    this.authService.token.subscribe(token => {
                        if (!token) {
                            return;
                        }
                        this.getInactivityPeriodFromDB(token);
                    });
                }
            }
        });

        this.inactivityBehaviorSubject.subscribe(res => {
            if (!res) {
                return;
            }

            console.log(`User has been inactive for a long time`);

            if (lastModule === 'admin') {
                this.adminAuthService.logout();
            } else if (lastModule === 'gradebookModule' || lastModule === 'instructorModule') {
                window.location.assign(`${environment.CMSDomain}/account/autologout`);
            } else if (lastModule === 'studentModule') {
                if (this.inAttempt) {
                    console.log('The user is currenly in attempt');
                } else {
                    this.forceExit = true;
                    this.authService.logout(false);
                    this.alertService.closeAll();
                    this.router.navigate(['/']).then(() => {
                        this.forceExit = false;
                        this.alertService.createGenericAlert('You have been logged out', 'You have been logged out due to inactivity')
                    });
                }
            }
        });
    }

    getInactivityPeriodFromDB(token: string) {
        this.http.get<any>(`${environment.NApiDomain}/inactivity-period/${token}`).subscribe(res => {
            if (res.success) {
                this.inactivityPeriod = res.data?.SessionLogout;
            }
        })
    }
}
