import { Component, OnDestroy } from '@angular/core';
import { Pricing } from '../pricing';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../student/data.service';
import { User } from 'app/shared/user.service';
import { MatDialog } from '@angular/material/dialog';
import { StripePaymentComponent } from '../stripe-payment.component';
import { DeviceService } from '../device.service';
import { LoaderService } from '../loader.service';

import * as _cloneDeep from 'lodash.clonedeep'

@Component({
    selector: 'app-pay-for-class',
    templateUrl: './pay-for-class.component.html',
})
export class PayForClassComponent implements OnDestroy {
    service: string;
    classGuid: string;
    assessmentId: string;
    isAssessmentRoom: boolean;
    newStudent: boolean;
    userCreated: boolean = false;
    userData: any;
    classData: any;
    originalPrice: number;
    price: number;
    coupon: string;
    couponData: any

    showCoupon = false;
    couponError: string;

    error: string;

    constructor(
        public http: HttpClient,
        public pricing: Pricing,
        public router: Router,
        public route: ActivatedRoute,
        public dataService: DataService,
        public user: User,
        public dialog: MatDialog,
        public deviceService: DeviceService,
        public title: Title,
        private loaderService: LoaderService
    ) {
        this.loaderService.showLoading();
        this.classGuid = route.snapshot.params['class'] as string;
        this.assessmentId = route.snapshot.params['assessment'] as string;
        this.isAssessmentRoom = route.snapshot.params['assessmentType'] as string === 'room' ? true : false;
        this.service = this.route.snapshot.params['service'] as string;
        this.newStudent = this.route.snapshot.params['new'] as string === 'new' ? true : false;

        if (this.classGuid) {
            // Get Class Information
            this.http.get<any>(`${environment.NApiDomain}/get-class/${this.classGuid}`).subscribe(result => {
                if (result.success) {
                    this.classData = result.data;
                    this.user.data.classData = result.data;
                    this.http.post<any>(`${environment.NApiDomain}/student-paid-price`, {customerId: result.data.CustomerId}).subscribe(priceRes => {
                        if (!priceRes.success) {
                            this.error = priceRes.error;
                            this.loaderService.hideLoading();
                            return;
                        }

                        this.price = priceRes.studentPaidPrice;
                        this.originalPrice = priceRes.studentPaidPrice;
                        this.loaderService.hideLoading();
                    });
                    this.title.setTitle(`Payment for ${result.data.className}`);
                } else {
                    this.error = result.error;
                    this.loaderService.hideLoading();
                }
            }, error => {
                this.error = 'Class not found. Make sure you copied the link correctly.';
                this.loaderService.hideLoading();
                console.log(error);
            });
        } else {
            this.loaderService.hideLoading();
            this.error = 'Class not found. Make sure you copied the link correctly.';
            console.log('Class guid is not found in the path');
        }

        this.userData = this.user.data;
        console.log(this.userData);
    }

    checkCoupon() {
        this.loaderService.showLoading();
        this.price = _cloneDeep(this.originalPrice);
        this.couponData = null;
        this.couponError = null;

        if (!this.coupon) {
            this.couponError = 'Please add a coupon code';
            this.loaderService.hideLoading();
            return;
        }

        this.coupon = this.coupon.trim();
        this.http.post<any>(`${environment.NApiDomain}/check-coupon`, { couponData: { coupon: this.coupon, type: 'student' } })
            .subscribe(res => {
                if (res.success) {
                    this.couponData = res.data;
                    this.price = Math.ceil(this.originalPrice * (100 - res.data.Discount) / 100);
                } else {
                    this.couponError = res.error;
                }
                this.loaderService.hideLoading();
            });
    }

    buy() {
        // 100% discount, let's just send this to the server with a random ID
        this.error = null;
        this.loaderService.showLoading();

        if (this.couponData && this.couponData.Discount === 100) {
            console.log('100% discount');
            this.stripeFinished(Math.round(Math.random() * 1000000000000) + '');
        } else {
            // opening dialog here which will give us back stripeToken
            const dialogRef = this.dialog.open(StripePaymentComponent, {
                data: {
                    totalAmount: this.price,
                    name: 'Extempore Student License',
                    descirption: '1 Student License',
                    email: false
                },
                width: this.deviceService.isOnMobile() ? '90%' : '60%'
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                    if (this.couponData && this.couponData.SingleUse) {
                        this.http.delete<any>(`${environment.NApiDomain}/delete-coupon/${this.coupon}`)
                            .subscribe(() => { });
                    }
                    this.stripeFinished(result.token);
                } else {
                    this.loaderService.hideLoading();
                }
            });
        }
    }

    stripeFinished(stripeToken: string) {
        if (this.newStudent && !this.userCreated) {
            this.user.createStudent(this.service === 'google-classroom' ? 'google' : this.service).subscribe(result => {
                if (result.success) {
                    this.userData.displayName = result.user.displayName;
                    this.userData.token = result.user.token;
                    this.userData.email = result.user.email;
                    this.userData.username = result.user.username;
                    this.userData.allClassess = result.user.allClassess;
                    this.userCreated = true;
                    this.createCharge(stripeToken);
                } else {
                    this.loaderService.hideLoading();
                    console.log(result.error);
                    this.error = result.error;
                }
            });
        } else {
            this.createCharge(stripeToken);
        }
    }

    createCharge(stripeToken: string) {
        this.http.post<any>(`${environment.NApiDomain}/charge`, {
            token: this.userData.token,
            classGuid: this.classGuid,
            displayName: this.userData.displayName,
            className: this.classData.className,
            email: this.userData.email,
            couponCode: this.couponData ? this.coupon : null,
            couponType: 'student',
            couponDiscount: this.couponData ? this.couponData.Discount : null,
            stripeToken: stripeToken,
            fromLink: this.newStudent ? true : false,
            allClasses: this.userData.allClasses,
            username: this.userData.username
        }).subscribe(result => {
            if (result.success) {
                this.loaderService.showLoading();
                if (this.newStudent) {
                    if (this.service === 'google-classroom') {
                        this.dataService.refreshData().then(() => {
                            this.loaderService.hideLoading();
                            if (this.isAssessmentRoom) {
                                this.router.navigateByUrl(`classes/${this.classGuid}`);
                            } else {
                                this.router.navigateByUrl(`classes/${this.classGuid}/${this.assessmentId}`);
                            }
                        });
                    } else {
                        this.router.navigateByUrl('/n/success');
                    }
                } else {
                    if (this.deviceService.isOnMobile()) {
                        this.deviceService.redirectToClassInApp(this.classGuid);
                    } else {
                        this.dataService.refreshData().then(() => {
                            this.loaderService.hideLoading();
                            if (this.service === 'google-classroom') {
                                if (this.isAssessmentRoom) {
                                    this.router.navigateByUrl(`classes/${this.classGuid}`);
                                } else {
                                    this.router.navigateByUrl(`classes/${this.classGuid}/${this.assessmentId}`);
                                }
                            } else {
                                this.router.navigateByUrl(`classes/${this.classGuid}`);
                            }
                        });
                    }
                }
            } else {
                console.error(result);
                this.error = result.error;
                this.loaderService.hideLoading();
            }
        }, console.error);
    }

    ngOnDestroy() {
        this.loaderService.hideLoading();
    }
}
