import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'hMinSecPipe'})
export class HMinSecPipe implements PipeTransform {
    transform(totalSeconds: number): string {
        totalSeconds = Math.ceil(totalSeconds);
        let result = '';
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        if (hours) {
            result += `${hours} h, `;
        }
        if (minutes) {
            result += `${minutes} min`;
        }
        if (minutes && seconds) {
            result += ' and '
        }
        if (seconds) {
            result += `${seconds} sec`;
        }

        return result;
    }
}
