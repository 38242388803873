import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class Pricing {
    // renderedPrice;
    // renderedUnitPrice;

    constructor(
        private http: HttpClient
    ) { }

    calculateLicensePrice(students: number, discount: number) {
        students = students || 1;
        discount = discount || 0;
        return Math.ceil(this.getUnitPrice(students) * (100 - discount) / 100) * students;
    }

    calculateMonthlyPrice(months: number, discount: number) {
        months = months || 1;
        discount = discount || 0;

        return Math.ceil(this.getMonthlyPrice(months) * (100 - discount) / 100) * months;
    }

    calculateIndividualEducatorPrice(months: number, discount: number) {
        months = months || 1;
        discount = discount || 0;

        return Math.ceil(this.getIndividualEducatorPrice(months) * (100 - discount) / 100) * months;
    }

    getUnitPriceWithDiscount(students: number, discount: number) {
        return Math.ceil(this.getUnitPrice(students) * (100 - discount) / 100);
    }

    getMonthlyPriceWithDiscount(months: number, discount: number) {
        return Math.ceil(this.getMonthlyPrice(months) * (100 - discount) / 100);
    }

    getIndividualEducatorPriceWithDiscount(months: number, discount: number) {
        return Math.ceil(this.getIndividualEducatorPrice(months) * (100 - discount) / 100);
    }

    getStudentPaidPrice(customerId: number | string): Observable<any> {
        return this.http.post<any>(`${environment.NApiDomain}/student-paid-price`, {customerId: customerId});
    }

    getDefaultStudentPaidPrice() {
        return 1299;
    }

    // MAKE SURE THE PRICES MATCH /lib/prices.js
    getUnitPrice(students?: number) {
        if (students <= 30) {
            return 1099;
        } else if (students <= 200) {
            return 1009;
        } else if (students <= 500) {
            return 929;
        } else if (students <= 1000) {
            return 900;
        } else {
            return 850;
        }
    }

    getMonthlyPrice(months: number) {
        if (months <= 1) {
            return 2990;
        } else if (months <= 6) {
            return 2490;
        } else if (months <= 12) {
            return 2290;
        }
    }

    getIndividualEducatorPrice(months: number) {
        if (months <= 3) {
            return 999;
        } else if (months <= 6) {
            return 949;
        } else if (months <= 9) {
            return 899;
        } else {
            return 849;
        }
    }

    getPlanOfferPrice(months: number) {
        if (months === 3) {
            return 2899;
        } else if (months === 6) {
            return 5599;
        } else if (months === 9) {
            return 7999;
        }
    }
}
