import { ErrorHandler, Injectable } from '@angular/core';
import { Notify } from './notify.service';
import { environment } from 'environments/environment';

import * as Sentry from '@sentry/browser';
Sentry.init({
    dsn: 'https://d9fe034cf1d549c8b219776917e49cab@sentry.io/299138',
    environment: environment.name,
    beforeSend(event, hint) {
        if (hint.originalException === "Timeout") return null;
        return event;
    }
});

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(public notify: Notify) {}
    handleError(error) {
        console.error(error);
        if (environment.name !== 'development') {
            if (error.toString() === '[object Object]') {
                try {
                    error = JSON.stringify(error);
                } catch (stringifyError) {
                    error = '[object Object] that we were not able to stringify from ' + window.location;
                }
            }
            Sentry.captureException(error);
            this.notify.error(error, [error.stack]);
        }
        throw error;
    }
}
