import { AfterViewInit, Component, ElementRef, Input, OnChanges, Renderer2, ViewChild } from "@angular/core";
import { marked } from "marked";

@Component({
    selector: 'render-markdown',
    templateUrl: './render-markdown.component.html'
})
export class RenderMarkdownComponent implements OnChanges, AfterViewInit {
    @Input() text: string;
    @ViewChild('dynamicContent') dynamicContent: ElementRef;
    markedText: string;

    constructor(
        private renderer: Renderer2
    ) { }

    ngOnChanges() {
        if (!this.text) {
            return;
        }

        this.markedText = marked.parse(this.text);
    }

    ngAfterViewInit() {
        const firstChild = this.dynamicContent.nativeElement.firstChild;
        if (firstChild) {
            this.renderer.setStyle(firstChild, 'margin-top', '0');
        }
    }
}
