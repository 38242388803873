import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IntercomModule } from 'ng-intercom';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from './shared/shared.module';
import { Notify } from './notify.service';
import { GlobalErrorHandler } from './error-handler';
import { PermissionsService } from './shared/record/permissions.service';
import { AppComponent } from './app.component';
import { FileNotFoundComponent } from './404.component';
import { HttpInterceptor } from './http.interceptor';
import { MatRoomsComponent } from './shared/mat-rooms.component';
import { NoNetworkComponent } from './shared/no-network.component';
import { NavService } from './shared/nav.service';
import { StripePaymentComponent } from './shared/stripe-payment.component';
import { CurrentModuleService } from './current-module.service';
import { AuthService } from './shared/auth.service';
import { RequireAuthGuard } from './shared/require-auth.guard';
import { IonicAlertsService } from './ionic-alerts.service';
import { AccountService } from './shared/account.service';
import { DataService } from './student/data.service';
import { TwilioService } from './shared/twilio.service';
import { environment } from 'environments/environment';
import { AdminAuthService } from './admin/admin-auth.service';
import { MatClearAnswerComponent } from './shared/mat-clear-answer.component';
import { RecordingService } from './shared/record/recording.service';
import { MatTeacherSsoComponent } from './shared/mat-teacher-sso.component';
import { MatSendToLMSComponent } from './shared/mat-send-to-lms';
import { AdminHttp } from './admin/admin-http.service';
import { UserInactivityService } from './shared/user-inactivity.service';

@NgModule({
    declarations: [
        AppComponent,
        FileNotFoundComponent,
        MatClearAnswerComponent,
        MatRoomsComponent,
        MatTeacherSsoComponent,
        MatSendToLMSComponent,
        NoNetworkComponent,
        StripePaymentComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatFormFieldModule,
        MatDialogModule,
        MatIconModule,
        SharedModule,
        MatCheckboxModule,
        RouterModule.forRoot([
            {
                path: '',
                loadChildren: () => import('./student/student.module').then(m => m.StudentModule)
            },
            {
                path: 'new',
                loadChildren: () => import('./new-account/new-account.module').then(m => m.NewAccountModule),
            },
            {
                path: 'upgrade',
                loadChildren: () => import('./upgrade-account/upgrade-account.module').then(m => m.UpgradeAccountModule),
            },
            {
                path: 'n',
                loadChildren: () => import('./new-student/new-student.module').then(m => m.NewStudentModule),
            },
            {
                path: 'instructor',
                loadChildren: () => import('./instructor/instructor.module').then(m => m.InstructorModule),
            },
            { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
            { path: 'demo', loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule) },
            { path: '**', component: FileNotFoundComponent },
        ]),
        IonicModule.forRoot(),
        IntercomModule.forRoot({
            appId: environment.intercomAppId,
            updateOnRouterChange: true
        }),
        ToastrModule.forRoot({
            timeOut: 4000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            progressBar: true
        })
    ],
    exports: [
        NoNetworkComponent
    ],
    providers: [
        CookieService,
        AdminAuthService,
        AdminHttp,
        Notify,
        PermissionsService,
        NavService,
        CurrentModuleService,
        AuthService,
        RequireAuthGuard,
        IonicAlertsService,
        AccountService,
        DataService,
        TwilioService,
        RecordingService,
        DatePipe,
        UserInactivityService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
