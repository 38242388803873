import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    template: `
    <div style="height:400px;display:flex;flex-direction:column;justify-content:center;align-items:center;font-weight:bold;">
        <p>You have accessed a URL that is no longer available.</p>
        <p><a routerLink="/">Return to the Extempore Home</a></p>
    </div>
    `
})
export class FileNotFoundComponent {
    environment = environment;
}
