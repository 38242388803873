import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Component, Input, TemplateRef, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

import { DataService } from 'app/student/data.service';
import { STATIC_DATA } from 'app/static-data';
import { DeviceService } from './device.service';
import { IonicAlertsService } from 'app/ionic-alerts.service';
import { LoaderService } from './loader.service';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';

@Component({
    selector: 'user-account',
    templateUrl: 'account.component.html',
})
export class AccountComponent implements OnChanges, OnDestroy {
    @ViewChild('moveClassDialog', {static: false}) moveClassDialog: TemplateRef<any>;

    @Input()
    token: string;
    error: string;

    profile: Observable<any>;
    profileInfo: any;
    newClass: string;
    classList;
    selectedUnenrollClass;
    newClassGuid: string;
    moveClassMessage: string;
    classesLoading = false;
    errors: ErrorsForm = new ErrorsForm();

    classesSubscribe: Subscription;
    tokenSubscibe: Subscription;

    regExps = STATIC_DATA;
    firebaseUser: Observable<any>;

    studentId: number;
    intercomInternalContactId: string;
    edlinkId: string;

    profileForm: NgForm;
    passwordForm: NgForm;
    usernameForm: NgForm;
    unenrollForm: NgForm;
    moveClassForm: NgForm;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public http: HttpClient,
        public auth: AuthService,
        public data: DataService,
        public dialog: MatDialog,
        public deviceService: DeviceService,
        private alert: AlertController,
        private loaderService: LoaderService,
        private alertService: IonicAlertsService,
        public accountService: AccountService
    ) { }

    ngOnChanges() {
        if (this.token) {
            console.log('Have a token.');
            this.auth.syncToken = this.token;
            this.auth.token.next(this.token);
            this.loaderService.showLoading();
            this.profile = this.http.get(`${environment.NApiDomain}/${this.token}/profile`).pipe(
                tap(profileInfo => {
                    this.loaderService.hideLoading();
                    this.studentId = profileInfo.Id;
                    this.intercomInternalContactId = profileInfo.IntercomInternalContactId;
                    this.edlinkId = profileInfo.EdlinkId;
                    if (profileInfo.error) {
                        this.error = profileInfo.error;
                    }
                    this.accountService.profilePicture.next(profileInfo.profileImage);
                    this.profileInfo = profileInfo;
                })
            )
        } else {
            console.error('Need an ID to render a profile.');
        }
    }

    save(form) {
        this.errors.firstName = null;
        this.errors.lastName = null;
        this.errors.email = null;

        let errorMsg = 'Please complete this field.';
        let valid = true;
        if (!form.value.firstName) {
            this.errors.firstName = errorMsg;
            valid = false;
        }

        if (!form.value.lastName) {
            this.errors.lastName = errorMsg;
            valid = false;
        }

        if (!form.value.email) {
            this.errors.email = errorMsg;
            valid = false;
        } else {
            if (!this.regExps.EMAIL_REGEX.test(form.value.email)) {
                this.errors.email = 'Please provide a valid e-mail address.';
                valid = false;
            }
        }

        if (valid) {
            this.auth.updateUserProfile(form.value).then(result => {
                if (result) {
                    this.alertService.createGenericAlert('Done', 'Profile update saved!');
                    if (!this.profileInfo.UserName.endsWith('-student')) {
                        // Also update the corresponding Intercom Contact
                        this.http.post<any>(`${environment.NApiDomain}/update-intercom-contact`, {intercomInternalContactId: this.intercomInternalContactId, username: this.profileInfo.UserName,
                            email: form.value.email, name: form.value.firstName + " " + form.value.lastName, role: "student", edlinkId: this.edlinkId})
                            .subscribe();
                    }
                }
            });
        }
    }

    savePassword(form) {
        this.errors.password = null;

        if (!form.value.password || !form.value.confirmPassword) {
            this.errors.password = 'Please complete all of the fields.'
        } else if (form.value.password.length < 8) {
            this.errors.password = 'Your password must be at least 5 characters.';
        } else if (form.value.password !== form.value.confirmPassword) {
            this.errors.password = 'Your passwords must match';
        } else {
            this.auth.updateUserPassword(form.value).then(result => {
                if (result) {
                    this.alertService.createGenericAlert('Done', 'Pasword successfully updated!');
                }
            });
        }
    }

    saveUsername(form, isInstructor: boolean) {
        this.errors.username = null;
        let regex = this.regExps.USERNAME_REGEX;

        if (!form.value.username) {
            this.errors.username = 'What would you like your username to be?';
        } else if (!regex.test(form.value.username)) {
            this.errors.username = 'Usernames need to be between 8 and 25 characters and cannot include special characters.';
        } else {
            let username = form.value.username.toLocaleLowerCase();
            this.http.get<any>(`${environment.NApiDomain}/check-username/${username}`).subscribe(result => {
                if (result.success && !result.exists) {
                    this.auth.updateUsername(form.value, isInstructor).then(result => {
                        if (result) {
                            this.alertService.createGenericAlert('Done', 'Username has been successfully updated!');
                            if (!form.value.username.endsWith('-student')) {
                                // Also update the corresponding Intercom Contact
                                this.http.post<any>(`${environment.NApiDomain}/update-intercom-contact`, {intercomInternalContactId: this.intercomInternalContactId, username: form.value.username,
                                    email: this.profileInfo.Email, name: this.profileInfo.FirstName + " " + this.profileInfo.LastName, role: "student", edlinkId: this.edlinkId})
                                    .subscribe();
                            }
                        }
                    });
                } else {
                    this.errors.username = `Someone else is using "${username}" as a username. Please choose a different one.`;
                }
            });
        }
    }

    googleConnection() {
        this.loaderService.showLoading();
        if (this.auth.googleConnected) {
            this.auth.disconnectGoogle().then(() => {
                this.loaderService.hideLoading();
            });
        } else {
            this.auth.connectGoogle(this.token).then(() => {
                this.loaderService.hideLoading();
            });
        }
    }

    appleConnection() {
        this.loaderService.showLoading();
        if (this.auth.appleConnected) {
            this.auth.disconnectApple().then(() => {
                this.loaderService.hideLoading()
            });
        } else {
            this.auth.connectApple().then(() => {
                this.loaderService.hideLoading()
            });
        }
    }

    cleverConnection() {
        this.loaderService.showLoading();
        if (this.auth.cleverConnected) {
            this.auth.disconnectClever(this.token).then(() => {
                this.loaderService.hideLoading();
            }, error => {
                this.loaderService.hideLoading();
                this.error = error;
            });
        } else {
            let url;
            if (this.profileInfo.RoleId === 2) {
                url = `${environment.StudentDomain}/clever/connect/${this.token}`;
            } else {
                url = `${environment.StudentDomain}/clever/connect`;
            }

            const uri = encodeURI(url);
            location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${uri}&client_id=${environment.CleverId}&district_id=${environment.CleverDistrict}`;
            this.loaderService.hideLoading();
        }
    }

    classLinkConnection() {
        this.loaderService.showLoading();
        if (this.auth.classLinkConnected) {
            this.auth.disconnectClassLink(this.token).then(() => {
                this.loaderService.hideLoading();
            }, error => {
                this.loaderService.hideLoading();
                this.error = error;
            });
        } else {
            let url;
            if (this.profileInfo.RoleId === 2) {
                url = `${environment.NapiStudentDomain}/class-link/connect/${this.token}`;
            } else {
                url = `${environment.NapiStudentDomain}/class-link/connect`;
            }

            const uri = encodeURI(url);
            location.href = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${uri}&client_id=${environment.ClassLinkId}&response_type=code&state=${environment.ClassLinkState}`;
            this.loaderService.hideLoading();
        }
    }

    updateProfileImage() {
        const uploadHandler = event => {
            if (event && event.target['files']) {
                this.loaderService.showLoading();
                console.log('File change event fired!', event);
                console.log('Files include: ', event.target['files'][0]);
                this.xhrProfileImage(event.target['files'][0], this.token).then(imgSrc => {
                    this.alertService.createGenericAlert('Done', 'Profile image successfully updated!');
                    // @TODO / @WARNING this DOES cause a race condition if the file upload or DB update haven't finished
                    // A 1 second timer reduces the chances of this problem
                    setTimeout(() => {
                        this.loaderService.hideLoading();
                        this.accountService.profilePicture.next(imgSrc);
                    }, 1000);
                });
                document.getElementById('pic').removeEventListener('change', uploadHandler);
                this.loaderService.hideLoading();
            };
        }
        document.getElementById('pic').removeEventListener('change', uploadHandler);
        document.getElementById('pic').click();
        document.getElementById('pic').addEventListener('change', uploadHandler);
    }

    moveClass({value: { newClass }}) {
        this.newClassGuid = !!newClass.split('/n/').length ? newClass.split('/n/')[1].replace(/\//g, '') : '';
        this.classesLoading = true;
        this.moveClassMessage = '';

        this.classesLoading = false;
        this.classesSubscribe = this.data.classes.subscribe(list => {
            this.classList = list;
            const isEnrolled = !!this.classList.find(({IdGuid}) => IdGuid.toLowerCase() === this.newClassGuid.toLowerCase());
            if (!isEnrolled) {
                this.dialog.open(this.moveClassDialog, {height: '350px'});
            } else {
                this.moveClassMessage = 'You are already enrolled in this class.';
            }
        });
    }

    unenroll(form, dialogRef) {
        this.loaderService.showLoading();
        dialogRef.close();
        this.tokenSubscibe = this.auth.token.subscribe(TokenID => {
            if (TokenID) {
                this.http.post<any>(`${environment.NApiDomain}/move-class`, {
                    TokenID,
                    ClassId: this.newClassGuid,
                    OldClassId: this.selectedUnenrollClass,
                }).subscribe((res) => {
                    if (res.success) {
                        this.selectedUnenrollClass = null;
                        this.newClassGuid = null;

                        this.data.refreshData().then(() => {
                            this.loaderService.hideLoading();
                            this.moveClassMessage = 'Moved successfully.';
                            this.router.navigate(['/']);
                        });
                    } else {
                        this.moveClassMessage = res.message;
                        this.loaderService.hideLoading();
                    }
                }, err => {
                    console.log('Erorr unrolling:', err);
                    this.loaderService.hideLoading();
                })
            } else {
                this.moveClassMessage = 'An error occurred, please try again later.';
                this.loaderService.hideLoading();
            }
        })
    }
    xhrProfileImage(file, token): Promise<any> {
        console.log('Sending XHR to upload image.');
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();

            const xhr = new XMLHttpRequest();
            formData.append('profileImage', file, file.name);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        let resoponseJson = JSON.parse(xhr.response);
                        if (resoponseJson.success === true) {
                            resolve(resoponseJson.profileImage);
                        } else {
                            const res = JSON.parse(xhr.response);
                            reject(res);
                        }
                    }
                }
            };
            xhr.open('POST', `${environment.NApiDomain}/${token}/profile/file`, true);
            xhr.send(formData);
        });
    }

    deleteAccount() {
        this.alert.create({
            header: 'Delete Account',
            message: 'Proceeding will delete your user and all your data. This action cannot be undone. To confirm please type <strong>DELETE</strong> in the box below',
            inputs: [
                {name: 'delete', type: 'text', placeholder: 'Confirm'}
            ],
            buttons: [
                {text: 'Cancel', role: 'cancel'},
                {text: 'Ok', handler: (alertData) => {
                    this.checkDeleteMessage(alertData);
                }}
            ]
        }).then(alert => {
            alert.present();
        });
    }

    checkDeleteMessage(data) {
        this.loaderService.showLoading();
        if (!data.delete || data.delete !== 'DELETE') {
            this.deleteAccountIncorrectPopup();
            this.loaderService.hideLoading();
        } else {
            if (this.studentId) {
                this.http.post<any>(`${environment.NApiDomain}/delete-student`, {studentId: this.studentId, intercomInternalContactId: this.intercomInternalContactId}).subscribe(deleteRes => {
                    if (deleteRes.success) {
                        this.alertService.createGenericAlert('Account Delete Successful', 'Your Extempore account and all data associated with it have been deleted. You will now be logged out.', null, false, () => {this.logoutOnDelete()});
                        this.loaderService.hideLoading();
                    }
                });
            } else {
                this.alertService.createGenericAlert('Error', 'No student ID provided for delete account');
                this.loaderService.hideLoading();
            }
        }
    }

    deleteAccountIncorrectPopup() {
        this.alert.create({
            header: 'Delete Account',
            message: 'Proceeding will delete your user and all your data. This action cannot be undone. To confirm please type <strong>DELETE</strong> in the box below.<br/><span class="error">Please enter the correct confirmation in capital letters in order to delete</span>',
            inputs: [
                {name: 'delete', type: 'text', placeholder: 'Confirm'}
            ],
            buttons: [
                {text: 'Cancel', role: 'cancel'},
                {text: 'Ok', handler: (alertData) => {
                    this.checkDeleteMessage(alertData);
                }}
            ]
        }).then(alert => {
            alert.present();
        });
    }

    logoutOnDelete() {
        this.auth.logout(true);
    }

    ngOnDestroy() {
        this.loaderService.hideLoading();
        this.dialog.closeAll();
        if (this.classesSubscribe) {
            this.classesSubscribe.unsubscribe();
        }
        if (this.tokenSubscibe) {
            this.tokenSubscibe.unsubscribe();
        }
    }
}

export class ErrorsForm {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    username: string;
}
