import { Injectable, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';

@Injectable()
export class DeviceService {

    constructor(
        private alert: AlertController,
        private zone: NgZone,
    ) { }

    /**
     * Return column or row
     * in order to determin the style of showing the question and answer boxes
     */
    attemptStyle(): string {
        if (this.isOnMobile()) {
            if (window.innerWidth <= 768) {
                return 'column';
            } else {
                return 'row';
            }
        } else {
            return 'row';
        }
    }

    isOnMobile() {
        if (window.location.href.includes('demo/')) {
            return true;
        } else {
            return (this.getDevicePlatform() === 'android' || this.getDevicePlatform() === 'ios') ? true : false;
        }
    }

    isApp() {
        if (Capacitor.isNativePlatform()) {
            return true;
        } else {
            return false;
        }
    }

    getPlatform() {
        if (this.isApp()) {
            return Capacitor.getPlatform();
        } else {
            return this.getDevicePlatform();
        }
    }

    getAppVersion(): Promise<any> {
        return new Promise(resolve => {
            if (this.isApp()) {
                App.getInfo().then(res => {
                    resolve(res.version);
                });
            } else {
                resolve(null);
            }
        });
    }

    isIos() {
        return this.isApp() && this.getPlatform() === 'ios';
    }

    isiPad(): boolean {
        if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
            return true;
        } else {
            return false;
        }
    }

    supportsCapture() {
        return (
            (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window['MediaRecorder'])
            || this.isIos()
        );
    }

    private getDevicePlatform(): string {
        if (navigator.userAgent.match(/Android/i)) {
            return 'android';
        } else if (navigator.userAgent.match(/BlackBerry/i)) {
            return 'BlackBerry';
        } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            return 'ios';
        } else if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
            return 'ios';
        } else if (navigator.userAgent.match(/Opera Mini/i)) {
            return 'Opera';
        } else if (navigator.userAgent.match(/IEMobile/i)) {
            return 'Windows';
        } else {
            return 'Browser';
        }
    }

    async lockOrientation() {
        if (!this.isApp()) {
            return;
        }

        const currentOrientation = await ScreenOrientation.orientation();
        await ScreenOrientation.lock({ orientation: currentOrientation.type });
    }

    async unlockOrientation() {
        if (!this.isApp()) {
            return;
        }

        await ScreenOrientation.unlock();
    }

    checkCaptureAndDevice() {
        if (!this.supportsCapture()) {
            this.alert.create({
                header: 'Browser Capture Required',
                message: 'You are currently using a browser that does not support video and audio capture. This capability is required for recording in Extempore. For the best experience while using Extempore, please ensure that you have the latest version of Chrome or Firefox installed or download our app.',
                buttons: [
                    { text: 'Cancel', role: 'cancel' },
                    { text: 'Get The App', handler: this.redirectToApp }
                ]
            }).then(alert => {
                alert.present();
            });
        } else if (this.isOnMobile() && !this.isApp()) {
            this.alert.create({
                header: 'Extempore App',
                backdropDismiss: false,
                message: 'We noticed you are trying to log in from a mobile device. Extempore is best used on the native app.',
                buttons: [
                    { text: 'Go to the App', handler: this.redirectToApp }
                ]
            }).then(alert => {
                alert.present();
            });
        }
    }

    redirectToApp = () => {
        window.location.href = 'extempore://';
        this.takeUserToMobileStores();
    }

    redirectToAccount(doNotOpenMobileStore?: boolean) {
        window.location.href = 'extempore://account';
        this.takeUserToMobileStores(doNotOpenMobileStore);
    }

    redirectToClassInApp(classId: string, doNotOpenMobileStore?: boolean) {
        window.location.href = `extempore://class_${classId}`;
        this.takeUserToMobileStores(doNotOpenMobileStore);
    }

    redirectToAppWithToken(token: string, doNotOpenMobileStore?: boolean) {
        window.location.href = `extempore://token_${token}`;
        this.takeUserToMobileStores(doNotOpenMobileStore);
    }

    redirectToAppWithTokenAndClassId(token: string, classGuid: string, doNotOpenMobileStore?: boolean) {
        window.location.href = `extempore://token_${token}&classGuid_${classGuid}`;
        this.takeUserToMobileStores(doNotOpenMobileStore);
    }

    redirectToAppWithTokenClassIdAndAssessmentId(token: string, classGuid: string, assessmentId: string, doNotOpenMobileStore?: boolean) {
        window.location.href = `extempore://token_${token}&classGuid_${classGuid}&assessmentId_${assessmentId}`;
        this.takeUserToMobileStores(doNotOpenMobileStore);
    }

    redirectToAppForSyncRoom(token: string, classGuid: string, assessmentId: string, questionId: string, roomId: string, doNotOpenMobileStore?: boolean) {
        window.location.href = `extempore://sync-room&token_${token}&classGuid_${classGuid}&assessmentId_${assessmentId}&questionId_${questionId}&roomId_${roomId}`;
        this.takeUserToMobileStores(doNotOpenMobileStore);
    }

    redirectToAppForAsyncRoom(token: string, classGuid: string, assessmentId: string, questionId: string, roomId: string, doNotOpenMobileStore?: boolean) {
        window.location.href = `extempore://async-room&token_${token}&classGuid_${classGuid}&assessmentId_${assessmentId}&questionId_${questionId}&roomId_${roomId}`;
        this.takeUserToMobileStores(doNotOpenMobileStore);
    }

    takeUserToStoreNow() {
        if (this.getPlatform() === 'android') {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.extemporeapp.mobile&hl=en_US';
        } else if (this.getPlatform() === 'ios') {
            window.location.href = 'https://itunes.apple.com/us/app/extempore/id1062018455';
        }
    }

    private takeUserToMobileStores(doNotOpenMobileStore?: boolean) {
        if (doNotOpenMobileStore) {
            return;
        }

        setTimeout(() => {
            this.zone.run(() => {
                if (document.hasFocus()) {
                    if (this.getPlatform() === 'android') {
                        window.location.href = 'https://play.google.com/store/apps/details?id=com.extemporeapp.mobile&hl=en_US';
                    } else if (this.getPlatform() === 'ios') {
                        window.location.href = 'https://itunes.apple.com/us/app/extempore/id1062018455';
                    }
                }
            });
        }, 3000);
    }

}
