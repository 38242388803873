import { Component, NgZone } from '@angular/core';
import { environment } from 'environments/environment';
import { DeviceService } from './device.service';

@Component({
    selector: 'apps-download',
    templateUrl: './apps-download.component.html'
})
export class AppsDownloadComponent {
    environment = environment;

    constructor(
        public zone: NgZone,
        public deviceService: DeviceService
    ) { }

    navigateToStudentWeb() {
        window.location.href = environment.StudentDomain;
    }
}
