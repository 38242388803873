import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { AttemptOverlayComponent } from './attempt-overlay.component';


@Injectable()
export class RecordingOverlayService {
    overlayRef: OverlayRef;

    constructor(
        public overlay: Overlay,
    ) { }

    showOverlay(): Promise<any> {
        return new Promise(resolve => {
            this.openOverlay();
            setTimeout(() => {
                this.closeOverlay();
                resolve(null);
            }, 3500);
        });
    }

    private openOverlay() {
        let config = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'overlay-dark-background',
            positionStrategy: this.overlay.position().global().centerHorizontally()
          });

        this.overlayRef = this.overlay.create(config);
        this.overlayRef.attach(new ComponentPortal(AttemptOverlayComponent));
    }

    private closeOverlay() {
        this.overlayRef.detach();
    }
}
