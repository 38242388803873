import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AdminHttp {
    logoutUser = new Subject<boolean>();

    constructor(
        private http: HttpClient,
    ) { }

    get<T>(url: string, params = {}): Observable<any> {
        let uid = JSON.parse(localStorage.getItem('adminUid'));
        if (!url.includes('auth/') && !uid) {
            this.logoutUser.next(true);
            return observableOf({success: false, error: 'No auth key found'});
        }

        if (uid) {
            params['auth'] = uid;
        }

        return this.http.get<T>(this.wrap(url), { params: params }).pipe(catchError(err => this.catch(err)));
    }
    post<T>(url: string, data?: any, params = {}) {
        let uid = JSON.parse(localStorage.getItem('adminUid'));
        if (!url.includes('auth/') && !uid) {
            this.logoutUser.next(true);
            return observableOf({success: false, error: 'No auth key found'});
        }

        if (uid) {
            params['auth'] = uid;
        }

        return this.http.post<T>(this.wrap(url), data, { params: params });
    }
    delete<T>(url: string, params = {}) {
        let uid = JSON.parse(localStorage.getItem('adminUid'));
        if (!url.includes('auth/') && !uid) {
            this.logoutUser.next(true);
            return observableOf({success: false, error: 'No auth key found'});
        }

        if (uid) {
            params['auth'] = uid;
        }

        return this.http.delete<T>(this.wrap(url), { params: params }).pipe(catchError(err => this.catch(err)));
    }
    wrap(url: string) {
        return `${environment.NApiDomain}/admin/${url}`;
    }
    catch(err) {
        console.log('Admin HTTP Received an error:', err);
        if (err.status === 401) {
            this.logoutUser.next(true);
            return observableOf({success: false, error: err});
        } else {
            return observableOf({success: false, error: err});
        }
    }
}
