import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
/**
 * A service with common methods for tracking and reporting problems
 **/
export class Notify {
    public eventLog: string[] = [];
    public userDetails = {token:undefined};

    constructor(private http: HttpClient, private injector: Injector) {
    }

    newAccount(opts: { type: string, accountName: string, name: string, username: string, email: string }) {
        const request = this.http.post(`${environment.NApiDomain}/notify`, { notificationType: 'newAccount', ...opts });
        return request;
    }
    /**
     * Log a failure that occurs while capturing audio or video
     */
    captureFailure(msg: string, eventLog?: string[]) {
        const router = this.injector.get(Router);
        const url = router.url;


        console.log('Sending capture error: ' + msg);
        this.http.post(`${environment.NApiDomain}/notify`, {
            notificationType: 'error',
            type: 'Capture Failure',
            msg: msg.toString(),
            eventLog: [...this.eventLog, eventLog],
            platform: navigator.platform,
            browser: navigator.userAgent,
            url: url,
            user: this.userDetails,
        }).subscribe();
    }

    /**
     * Log a general site error
     */
    error(msg: string, eventLog?: string[]) {
        const url = this.injector.get(Router).url;

        console.log('Sending error: ' + msg);
        this.http.post(`${environment.NApiDomain}/notify`, {
            notificationType: 'error',
            type: 'Generic Site Error',
            msg: msg.toString(),
            eventLog: [...this.eventLog, eventLog],
            platform: navigator.platform,
            url: url,
            browser: navigator.userAgent,
            user: this.userDetails,
        }).subscribe();
    }
    log(msg: string) {
        this.eventLog.push(msg);
    }
    setUserDetails(details) {
        this.userDetails = details;
    }
}
