import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
template: `
    <style>
        label {
            margin: 0;
            font-size: .95rem;
        }

        input {
            margin-right: 10px;
        }

        .text-area {
            padding: 2px;
            margin: 15px 0px 10px;
            font-size: 16px !important;
            resize: none;
            width: 100%;
            height: 100px;
        }

        .title {
            color: var(--gradebook-light-blue);
            font-family: "Fredoka One", cursive;
            font-size: 20px;
            margin-bottom: 20px;
        }

        .content {
            margin-bottom: 10px;
            font-size: .95rem;
        }

        .checkbox-container {
            display: flex;
        }

        .mat-dialog-actions > * + * {
            margin-left: 10px;
        }
    </style>
    <div class="popup">
        <div class="title">Are you sure?</div>
        <div>
            <div class="content">You are about to clear an attempt</div>
            <div class="checkbox-container">
                <input [(ngModel)]="notify" type="checkbox" />
                <label>Notify student via email that submission has been reset</label>
            </div>
            <textarea *ngIf="notify" class="text-area" [(ngModel)]="message" placeholder="[Optional] Message to be sent in the email notification">
            </textarea>
        </div>
        <div mat-dialog-actions style="float:right;">
            <ion-button (click)="cancel()" color="primary">Cancel</ion-button>
            <ion-button (click)="done()" color="success">Done</ion-button>
        </div>
    </div>
`
})
export class MatClearAnswerComponent {
    notify: boolean;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<MatClearAnswerComponent>
    ) { }

    done() {
        let message = this.message && this.message.trim().length > 0 ? this.message.trim() : null;
        this.dialogRef.close({confirm: true, notify: this.notify, message: message});
    }

    cancel() {
        this.dialogRef.close({confirm: false})
    }
}
