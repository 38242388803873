import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    template: `
        <style> 
            .room-link {
                margin: 2% auto;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2%;
                border-radius: 25px;
                cursor: pointer;
            }
            .room-link:hover {
                background-color: rgba(211, 211, 211, 0.5);
            }
            .room-link:active, .room-link:focus {
                background-color: rgba(211, 211, 211, 1);
                color: white;
            }
            .warning-message {
                color: red;
                font-weight:600;
            }
            .buttons-container {
                display:flex;
                justify-content: space-between;
                margin-top:15px;
            }
        </style>

        <div class="popup">
            <h2 class="callout">Please select which room you would like to see</h2>
            <p class="warning-message" *ngIf="message">{{ message }}</p>
            <div *ngIf="data.rooms && data.rooms.length != 0; else noRooms" class="rooms-coontainer">
                <div *ngFor="let room of data.rooms; let i = index" class="room-link" (click)="selectRoom(i)" tabindex="0">
                    <strong> Room {{i+1}} </strong>
                </div>
                <div class="popup-border"></div>
                <br>
            </div>
            <ng-template #noRooms>
                <p> No Rooms to display </p>
            </ng-template>
            <div class="buttons-container">
                <ion-button (click)="cancel()" color="danger">Cancel</ion-button>
                <ion-button (click)="goToRoom()" color="success">Go to room</ion-button>
            </div>
        </div>
    `
})
export class MatRoomsComponent implements OnDestroy {
    roomToSelect: number = this.data.rooms[0];
    message: string = null;

    constructor(
        public dialogRef: MatDialogRef<MatRoomsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { rooms: number[] }
    ) { }

    cancel() {
        this.dialogRef.close(false);
    }

    selectRoom(i: number) {
        this.roomToSelect = this.data.rooms[i];
    }

    goToRoom() {
        if (this.roomToSelect !== null) {
            let room = this.roomToSelect;
            this.dialogRef.close(room);
        } else {
            this.message = 'Please select a room first';
        }
        
    }

    ngOnDestroy() {
        this.message = null;
        this.roomToSelect = null;
    }
}
