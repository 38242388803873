import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    template: `
        <div style="color:var(--light-main); padding:5%">
            <h2>Congratulations!</h2>
            <h3>You have successfully <span style="color:var(--dark-main)">{{ data.action }}</span> for your account!</h3>
        </div>
        <button (click)="closePopup()" style="margin:2% auto" class="green button">Go to Classes!</button>
    `
})

export class SuccessfulPaymentComponent {

    constructor(
        public dialogRef: MatDialogRef<SuccessfulPaymentComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { action: string }
    ) { }

    closePopup() {
        this.dialogRef.close(true);
    }
}
