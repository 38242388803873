import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userFriendlyText',
  pure: false
})
export class UserFriendlyTextPipe implements PipeTransform {

    transform(value) {
        return value ? value.replace(/-/g, ' ') : '';
    }
}
