import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { LoaderComponent } from "./loader.component";

@Injectable()
export class LoaderService {
    private overlayRef: OverlayRef;
    private showingLoader: boolean;

    constructor(public overlay: Overlay) { }

    showLoading() {
        if (!this.showingLoader) {
            const config = new OverlayConfig({
                hasBackdrop: true,
                backdropClass: 'overlay-dark-background',
                positionStrategy: this.overlay.position().global().centerHorizontally()
            });

            this.overlayRef = this.overlay.create(config);
            this.overlayRef.attach(new ComponentPortal(LoaderComponent));
            this.showingLoader = true;
        }
    }

    hideLoading() {
        if (this.overlayRef && this.showingLoader) {
            this.showingLoader = false;
            this.overlayRef.detach();
        }
    }
}
