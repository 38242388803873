import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { IonicAlertsService } from 'app/ionic-alerts.service';
import { DataService } from 'app/student/data.service';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoaderService } from './loader.service';

@Component({
    selector: 'instructor-student-footer',
    template: `
        <style>
            .footer-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                padding: 10px;
                background-color: var(--green);
                font-family: 'Montserrat';
            }

            .footer-container > * + * {
                margin-left: 10px;
            }

            .buttons-container {
                display: flex;
                align-items: center;
            }

            .buttons-container > * + * {
                margin-left: 10px;
            }

            @media only screen and (max-width: 600px) {
                .buttons-container {
                    flex-direction: column;
                }

                .buttons-container > * + * {
                    margin-left: 0px;
                    margin-top: 10px;
                }
            }
        </style>
        <div class='footer-container'>
            <div>You are currently logged into Student View</div>
            <div style="font-style: italic">Resetting the test student will clear all history for this student, allowing you to view the course as a brand new student</div>
            <div class=buttons-container>
                <ion-button color="primary" (click)="resetStudent()">Reset Student</ion-button>
                <ion-button color="primary" (click)="exitStudentView()">Exit Student View</ion-button>
            </div>
        </div>
    `
})
export class InstructorStudentFooterComponent {

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private loaderService: LoaderService,
        private alertService: IonicAlertsService,
        private dataService: DataService
    ) {}

    resetStudent() {
        this.loaderService.showLoading();
        this.authService.token.pipe(take(1)).subscribe(token => {
            if (!token) {
                this.alertService.createGenericAlert('Error!', 'We experienced an error while trying to reset the student. Please try again');
                this.loaderService.hideLoading();
                return;
            }

            this.httpClient.post<any>(`${environment.NApiDomain}/instructor/reset-student`, {token: token}).subscribe(res => {
                if (!res.success) {
                    this.alertService.createGenericAlert('Error!', 'We experienced an error while trying to reset the student. Please try again');
                    this.loaderService.hideLoading();
                    return;
                }

                this.dataService.refreshData().then(refreshDataRes => {
                    if (refreshDataRes) {
                        this.alertService.createGenericAlert('Success!', 'The student data was successfuly reset');
                    } else {
                        this.alertService.createGenericAlert('Error!', 'We experienced an error. Please refresh the page.');
                    }
                    this.loaderService.hideLoading();
                });
            }, err => {
                console.log('Reset student error:', err);
                this.alertService.createGenericAlert('Error!', 'We experienced an error while trying to reset the student. Please try again');
                this.loaderService.hideLoading();
            });
        }, err => {
            console.log('Getting token error:', err);
            this.alertService.createGenericAlert('Error!', 'We experienced an error while trying to reset the student. Please try again');
            this.loaderService.hideLoading();
        });
    }

    exitStudentView() {
        close();
    }
}
