import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'app/student/data.service';
import { DeviceService } from './device.service';

@Component({
    template: `
        <style>
            .mat-dialog-content {
                margin: 20px 0px;
            }
            .question + .question {
                margin-top: 10px;
                border-top: 2px solid var(--dark-main);
                padding-top: 10px;
            }
            .question > * + * {
                margin-top: 5px;
            }
            .question:last-child() {
                margin-bottom: 20px;
            }
            h1 {
                color: var(--light-main);
                font-weight: bold;
            }
            .limitation-container {
                margin: 0px;
                padding: 0px;
                text-align: left;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .limitation-container > * + * {
                margin-left: 5px;
            }
            .title {
                color: var(--dark-main);
                font-weight: bold;
            }
            .sub-title {
                font-weight: bold;
            }
            .material-icons {
                width: 20px;
                height: 20px;
                font-size: 20px;
                color: var(--dark-main);
            }
            .icon {
                width: 19px;
                height: 19px;
                padding: 1px;
            }
            .multi-icon-container {
                display: flex;
                align-items: center;
            }
            .buttons-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        </style>

        <div class="popup">
            <h1 mat-dialog-title>Remaining Questions in "{{data.assessment.Name}}"</h1>
            <mat-dialog-content>
                <div class="question" *ngFor="let q of data.questions; let i = index">
                    <div class="limitation-container title">Q{{i + 1}}</div>
                    <div class="limitation-container">
                        <mat-icon *ngIf="q.responseType.value === 'video'">videocam</mat-icon>
                        <mat-icon *ngIf="q.responseType.value === 'audio'">mic</mat-icon>
                        <mat-icon *ngIf="q.responseType.value === 'text'">message</mat-icon>
                        <mat-icon *ngIf="q.responseType.value === 'mc'">radio_button_checked</mat-icon>
                        <img *ngIf="q.responseType.value === 'ap'" class="icon" src="../../../img/SVG/comments-regular.svg"/>
                        <div class="multi-icon-container" *ngIf="q.responseType.value == 'screenAndCamera'">
                            <mat-icon>present_to_all</mat-icon>
                            <mat-icon>videocam</mat-icon>
                        </div>
                        <div class="multi-icon-container" *ngIf="q.responseType.value == 'screenAndMicrophone'">
                            <mat-icon>present_to_all</mat-icon>
                            <mat-icon>mic</mat-icon>
                        </div>
                        <!-- <span>Response type:</span> -->
                        <span class="sub-title">{{q.responseType.name}}</span>
                    </div>
                    <p *ngIf="deviceService.isApp() && q.responseType.value.includes('screen')" class="limitation-container" style="color: var(--ion-color-danger)">Share screen is not supported in the Extempore app</p>
                    <p class="limitation-container">
                        <mat-icon>{{q.numberOfAttemptsLeft !== 1 ? 'lock' : 'lock_open'}}</mat-icon>
                        <span class="sub-title">{{q.friendlyNumberOfAttemptsLeftMessage}}</span>
                    </p>
                    <p class="limitation-container" *ngIf="q.HideQuestion">
                        <mat-icon>visibility_off</mat-icon>
                        <span class="sub-title">Question prompt will be hidden after timer end</span>
                    </p>
                    <p class="limitation-container" *ngIf="q.hasPrompt">
                        <mat-icon>autorenew</mat-icon>
                        <span>Replay media:</span>
                        <span class="sub-title">{{q.ReplayMedia ? 'Yes' : 'No'}}</span>
                    </p>
                    <p class="limitation-container" *ngIf="q.reviewTime">
                        <mat-icon>timer</mat-icon>
                        <span>Time to review:</span>
                        <span class="sub-title">{{q.reviewTime}}</span>
                    </p>
                    <p class="limitation-container" *ngIf="q.respondTime">
                        <mat-icon>av_timer</mat-icon>
                        <span>Time to respond:</span>
                        <span class="sub-title">{{q.respondTime}}</span>
                    </p>
                    <p class="limitation-container" *ngIf="q.minimumResponseTime">
                        <mat-icon>timelapse</mat-icon>
                        <span>Minimum response:</span>
                        <span class="sub-title">{{q.minimumResponseTime}}</span>
                    </p>
                </div>
            </mat-dialog-content>
            <div class="buttons-container">
                <ion-button (click)="cancel()" color="danger">Cancel</ion-button>
                <ion-button (click)="ok()" color="success">Start assessment</ion-button>
            </div>
        </div>
    `
})
export class MatQuestionLimitations {
    constructor(
        private dataService: DataService,
        public deviceService: DeviceService,
        public dialogRef: MatDialogRef<MatQuestionLimitations>,
        @Inject(MAT_DIALOG_DATA) public data: {assessment: any, questions: any[] }
    ) {
        this.data.questions.map(x => {
            x.numberOfAttemptsLeft = this.dataService.numberOfAttemptsLeft(x);
            x.friendlyNumberOfAttemptsLeftMessage = this.dataService.friendlyNumberOfAttemptsLeftMessage(x, true);

            if (x.ResponseType) {
                x.responseType = this.dataService.setFriendlyReponseType(x.ResponseType);
            }
            if (x.ReviewTime) {
                if (this.data.assessment.Extension) {
                    x.reviewTime = this.dataService.transformSecondsToMinAndSec(Math.ceil(x.ReviewTime * data.assessment.Extension));
                } else {
                    x.reviewTime = this.dataService.transformSecondsToMinAndSec(x.ReviewTime);
                }
            }
            if (x.RespondTime) {
                if (this.data.assessment.Extension) {
                    x.respondTime = this.dataService.transformSecondsToMinAndSec(Math.ceil(x.RespondTime * this.data.assessment.Extension));
                } else {
                    x.respondTime = this.dataService.transformSecondsToMinAndSec(x.RespondTime);
                }
                if (x.MinimumResponse && x.MinimumResponse !== 0 && x.responseType.value !== 'text' && !x.IsMultipleChoice) {
                    x.minimumResponse = this.dataService.transformSecondsToMinAndSec(x.MinimumResponse);
                }
                x.hasPrompt = x.audioUrl || x.videoUrl || x.YoutubeVideoId;
            }
        });
    }

    ok() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }

}
