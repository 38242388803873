import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'prettySeconds'})
export class PrettySecondsPipe implements PipeTransform {
    transform(seconds: number): string {
        if(seconds < 0 || typeof seconds != 'number' || isNaN(seconds)) {
            return '0:00';
        } else {
            return Math.floor(seconds / 60) + ":" + (seconds % 60 < 10 ? '0' : '') + (seconds % 60);
        }
    }
}
