import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavService {
    history = [];
    levelOneRoutes = [
        '/',
        '/classes',
        '/video-check',
        '/portfolio',
        '/demo/home'
    ];
    syncRoomBack = new BehaviorSubject<boolean>(null);

    constructor(
        private router: Router,
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                if (this.levelOneRoutes.indexOf(urlAfterRedirects) === -1) {
                    this.history = [...this.history, urlAfterRedirects];
                }
            });
    }

    popOne() {
        this.history.splice(-1);
    }

    pop() {
        this.history.splice(-2);
    }

    back() {
        let route = this.checkRoute();
        this.router.navigateByUrl(route).then(() => {
            this.history.splice(-2);
        });
    }

    backWithPromise(): Promise<void> {
        return new Promise(resolve => {
            let route = this.checkRoute();

            this.router.navigateByUrl(route).then(() => {
                this.history.splice(-2);
                resolve();
            });
        });
    }

    private checkRoute(): string {
        let route;
        if (this.history[0]) {
            if (this.history[0].includes('google-classroom/login')) {
                /**
                 * Remove the last part of the history item which indicates the assessment id
                 * so we can take the user back to the class on back button pressed.
                 * Remove the first in the list (which is google classroom login) so he can't
                 * get back to login
                 * and add classes at the begining of the list so the last back lends the user
                 * on the class list
                 */
                let historySplit = this.history[this.history.length - 1].split('/');
                historySplit.pop();
                route = historySplit.join('/');
                this.history.shift();
                this.history.unshift('classes');
            } if (this.history[0].includes('class-link') || this.history[0].includes('clever') || this.history[0].includes('edlink')) {
                route = 'classes';
            } else if (this.history[this.history.length - 2]) {
                route = this.history[this.history.length - 2];
            } else if (this.history[0].includes('demo/')) {
                route = 'demo/home';
            } else {
                route = 'classes';
            }
        } else {
            route = 'classes';
        }

        return route;
    }
}
