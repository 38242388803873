import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'app/student/data.service';

@Component({
    template: `
        <style>
            .buttons-container {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
            ion-button {
                    width:45%
                }

            ion-button, select {
                margin-top: 20px;
            }
        </style>

        <div class="popup">
            <h2 class="callout">Keyboard language select</h2>
            <div style="cursor: default;">From the dropdown below please select your preferred keyboard language</div>
            <select [(ngModel)]="languageCode">
                <option *ngFor="let lang of dataService.languages" [value]="lang.bcp47">
                    {{lang.englishName}}
                </option>
            </select>
            <div class="buttons-container">
                <ion-button color="danger" (click)="dialogRef.close(null)">Cancel</ion-button>
                <ion-button (click)="goToQuestion()" color="success" expand="block">Continue</ion-button>
            </div>
        </div>
    `
})
export class MatSelectLanguageComponent {
    languageCode: string = 'en';

    constructor(
        public dialogRef: MatDialogRef<MatSelectLanguageComponent>,
        public dataService: DataService
    ) { }

    goToQuestion() {
        this.dialogRef.close(this.languageCode);
    }
}
