import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";

import * as moment from 'moment';

@Injectable()
export class LicensesService {

    constructor(
        private http: HttpClient
    ) { }

    checkAccountValidityByClassData(classData: any) {
        if (classData.OrganizationId) {
            if (classData.orgTrial || classData.orgSubscriptionModel) {
                if (moment(classData.orgExpirationDate) < moment()) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            if (classData.IndividualEducator || classData.Trial || classData.SubscriptionModel) {
                if (moment(classData.ExpirationDate) < moment()) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }
    }

    checkIfClassPaymentNeeded(classData: any) {
        if (classData.OrganizationId) {
            if (classData.orgStudentPaid && !classData.Paid) {
                return true;
            } else {
                return false;
            }
        } else {
            if (classData.StudentPaid && !classData.Paid) {
                return true;
            } else {
                return false;
            }
        }
    }

    checkAccountValidity(classId: string, licensesNumber?: number): Observable<any> {
        return this.http.get<any>(`${environment.NApiDomain}/instructor/get-data/${classId}`)
            .pipe(map(data => {
                if (data && data.success && data.data) {
                    const customer = data.data;
                    if (customer) {
                        if (customer.StudentPaid) {
                            return { success: true, res: true };
                        } else if (customer.Lite) {
                            return { success: true, res: true };
                        } else if (customer.Trial) {
                            if (moment(customer.ExpirationDate) > moment()) {
                                return { success: true, res: true };
                            } else {
                                return { success: true, res: false };
                            }
                        } else if (customer.ConsumableLicenses) {
                            if (licensesNumber) {
                                if (licensesNumber < (customer.SubscriptionCount - customer.ConsumedLicenses)) {
                                    return { success: true, res: true };
                                } else {
                                    if (customer.OverUsage) {
                                        return { success: true, res: licensesNumber < Math.ceil(customer.SubscriptionCount * 1.1) - customer.ConsumedLicenses};
                                    } else {
                                        return { success: true, res: false };
                                    }
                                }
                            } else {
                                if (customer.ConsumedLicenses < customer.SubscriptionCount) {
                                    return { success: true, res: true };
                                } else {
                                    if (customer.OverUsage) {
                                        return { success: true, res: customer.ConsumedLicenses < Math.ceil(customer.SubscriptionCount * 1.1) };
                                    } else {
                                        return { success: true, res: false };
                                    }
                                }
                            }
                        } else if (customer.SubscriptionModel) {
                            if (moment(customer.ExpirationDate) > moment()) {
                                return { success: true, res: true };
                            } else {
                                return { success: true, res: false };
                            }
                        } else if (customer.IndividualEducator) {
                            if (moment(customer.ExpirationDate) > moment() && customer.ConsumedLicenses < customer.SubscriptionCount) {
                                return { success: true, res: true };
                            } else {
                                return { success: true, res: false };
                            }
                        } else {
                            return { success: true, res: true };
                        }
                    } else {
                        return { success: false };
                    }
                } else {
                    console.log('ERROR', data.message);
                    return { success: false };
                }
            }));
    }

    getAccountPaymentData(page: string, type?: string) {
        let accountData;
        if (page === 'buy-licenses') {
            accountData = { name: 'Consumable Licenses', description: 'Buy' }
            return accountData;
        } else if (page === 'extend-license') {
            accountData = { name: `${type === 'sm' ? 'Full License' : 'Individual Educator License'}`, description: 'Extend license for' }
            return accountData;
        } else {
            if (type === 'individual-educator') {
                accountData = { name: 'Individual Educator License', description: 'Buy license for', type: 'IndividualEducator' };
                return accountData;
            } else if (type === 'consumable-licenses') {
                accountData = { name: 'Consumable Licenses', description: 'Buy', type: 'ConsumableLicenses' };
                return accountData;
            }
        }
    }

    getFullTypeName(type: string, page: string): string {
        if (page === 'buy-licenses') {
            return 'ConsumableLicenses';
        } else {
            if (type === 'lite' || type === 'ie') {
                return 'IndividualEducator';
            } else if (type === 'sm') {
                return 'SubscriptionModel';
            }
        }
    }

    getLongTypeName(type: string) {
        if (type === 'lite') {
            return 'Lite';
        } else if (type === 'ie') {
            return 'IndividualEducator';
        } else if (type === 'cl') {
            return 'ConsumableLicenses';
        } else if (type === 'sm') {
            return 'SubscriptionModel';
        }
    }

    getCustomerFacingName(type: string) {
        if (type === 'ie' || type === 'IndividualEducator') {
            return 'Individual educator';
        } else if (type === 'cl' || type === 'ConsumableLicenses') {
            return 'Consumable licenses';
        } else if (type === 'sm' || type === 'SubscriptionModel') {
            return 'Full license';
        } else if (type === 'sp' || type === 'StudentPaid') {
            return 'Student paid'
        } else if (type === 'ip' || type === 'IP') {
            return 'IP (Legacy)'
        } else {
            return type;
        }
    }
}
