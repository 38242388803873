import { Injectable } from '@angular/core';

@Injectable()
export class RecordingService {

    constructor() { }

    getExtension(contentType: string): string {
        if (contentType.indexOf('ogg') !== -1) {
            return 'oga';
        } else if (contentType.indexOf('video') !== -1) {
            return 'mp4';
        } else if (contentType.indexOf('audio') !== -1) {
            return 'm4a';
        } else {
            return '';
        }
    }
}
