import { Router } from "@angular/router";
import { MediaRecorderComponent } from "./media-recorder.component";
import { DeviceService } from "app/shared/device.service";

export abstract class AbstractRecorder {
    eventLog: string[] = [];
    stream: MediaStream;
    recordRTC: any;
    startTime: number;
    timerCount: string;
    interval;

    /**
     * Setup is for when we are still initializing things
     * Ready is when the preview is active
     * Recording is when recording has started
     * Stopped is after the user has hit stop
     * Recorded is when the device has given us a blob.
     */
    state: 'setup' | 'ready' | 'recording' | 'stopped' | 'recorded' = 'setup';

    constructor(public component: MediaRecorderComponent, public deviceService: DeviceService, public router: Router) { }

    abstract connectAndPreview(): Promise<null>;

    start(): void {
        this.log('Recording started');
        this.state = 'recording';
        this.component.capture.emit(null);
        this.component.isRecording.emit(true);
    }

    stop(): void {
        this.component.isRecording.emit(false);
        this.state = 'stopped';

        if (this.deviceService.getPlatform() === 'ios') {
            return;
        }

        if (this.stream) {
            const audioTracks = this.stream.getAudioTracks();
            const videoTacks =  this.stream.getVideoTracks();

            if (audioTracks && audioTracks.length > 0) {
                audioTracks[0].stop();
            }

            if (videoTacks && videoTacks.length > 0) {
                videoTacks[0].stop();
            }

            if (this.component.type === 'audio') {
                this.component.destroyWave();
            }
        }
    }

    recordingComplete() {
        this.component.isRecording.emit(false);
        this.state = 'recorded';
    }

    rerecord() {
        this.connectAndPreview().then(() => {
            this.start();
        });
    }

    destroyTracks() {
        this.destroyTracks();
    }

    log(msg: string) {
        console.log(msg);
        this.eventLog.push(msg);
    }
}
