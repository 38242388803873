import { Injectable } from '@angular/core';

declare var cordova: any;

@Injectable()
export class PermissionsService {

    constructor() { }

    /**
     * Returns back array of 2 booleans
     * the first one is for camera check, the second one for microphone check
     */
    checkAllPermissions(): Promise<[boolean, boolean]> {
        return new Promise(resolve => {
            this.checkCameraPermissions().then(cameraRes => {
                this.checkMicrophonePermissions().then(microphoneRes => {
                    resolve([cameraRes, microphoneRes]);
                });
            });
        });
    }

    checkCameraPermissions(): Promise<boolean> {
        return new Promise(resolve => {
            document.addEventListener("deviceready", () => {
                const plugin = cordova.plugins.diagnostic;
                plugin.isCameraAvailable((check: any) => {
                    console.log(`Camera is ${check ? 'available' : 'not available'}`);
                    if (check) {
                        resolve(true);
                    } else {
                        this.requestCameraPermissions().then(cameraPermissions => {
                            resolve(cameraPermissions as boolean);
                        });
                    }
                }, (error: any) => {
                    console.error(`The following error occurred for camera check: ${error}`);
                    resolve(false);
                }, true)
            });
        });
    }

    checkMicrophonePermissions(): Promise<boolean> {
        return new Promise(resolve => {
            document.addEventListener("deviceready", () => {
            const plugin = cordova.plugins.diagnostic;
                plugin.isMicrophoneAuthorized((check: any) => {
                    console.log(`Microphone is ${check ? 'authorized' : 'denied'}`);
                    if (check) {
                        resolve(true);
                    } else {
                        this.requestMicrophnePermissions().then(microphonePermissions => {
                            resolve(microphonePermissions as boolean);
                        });
                    }
                }, (error: any) => {
                    console.error(`The following error occurred for michrophone check: ${error}`);
                    resolve(false);
                });
            });
        });
    }

    goToSettings() {
        const plugin = cordova.plugins.diagnostic;
        plugin.switchToSettings(() => {
            console.log('Successfully switched to Settings app');
        }, error => {
            console.log('Error when switching to settings', error);
        });
    }

    checkAllWebPermissions(): Promise<any> {
        return new Promise(resolve => {
            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
                if (!stream || !stream.active) {
                    console.error('Stream not active');
                    resolve({success: false, error: 'Stream not active'});
                } else {
                    return resolve({success: true});
                }
            }, (error: any) => {
                console.error('Check all web permissions error:', error.name);
                let returnErr;
                if (error.name == 'NotFoundError' || error.name == 'DevicesNotFoundError') {
                    returnErr = 'We couldn\'t find your camera. Please be sure to connect one and refresh the page';
                } else if (error.name == 'NotReadableError' || error.name == 'TrackStartError') {
                    returnErr = 'Your camera is in use by another program like Skype or Zoom. Please turn off the camera there and refresh the page';
                } else if (error.name == 'NotAllowedError' || error.name == 'PermissionDismissedError') {
                    returnErr = 'Please turn on permissions for your camera and refresh the page.';
                } else {
                    returnErr = error.message;
                }
                return resolve({success: false, error: returnErr});
            });
        });
    }

    checkMicrophoneWebPermissions(): Promise<boolean> {
        return new Promise(resolve => {
            navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(stream => {
                if (!stream || !stream.active) {
                    console.error('Stream not active');
                    resolve(false);
                } else {
                    return resolve(true);
                }
            }, (error: any) => {
                console.error('Check microphone web permissions error:', error.name);
                return resolve(false);
            });
        });
    }

    private requestCameraPermissions() {
        return new Promise(resolve => {
            const plugin = cordova.plugins.diagnostic;
            plugin.requestCameraAuthorization(status => {
                console.log(`Camera permissions are ${status}`);
                if (status === plugin.permissionStatus.GRANTED) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }, (error: any) => {
                console.error(`The following error occurred for camera request: ${error}`);
                resolve(false);
            },
            true
            )
        });
    }

    private requestMicrophnePermissions() {
        return new Promise(resolve => {
            const plugin = cordova.plugins.diagnostic;
            plugin.requestMicrophoneAuthorization(
                status => {
                    console.log(`Microphone permissions are ${status}`);
                    if (status === plugin.permissionStatus.GRANTED) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, (error: any) => {
                    console.error(`The following error occurred for michrophone request: ${error}`);
                    resolve(false);
                }
            );
        });
    }
}
