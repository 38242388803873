import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SettingsService {
    organizationData = new BehaviorSubject<any>(null);

    constructor(
        public authService: AuthService,
        public http: HttpClient
    ) {
        this.authService.token.subscribe(token => {
            if (token) {
                this.http.get<any>(`${environment.NApiDomain}/org-data-for-student/${token}`)
                .subscribe(res => {
                    this.organizationData.next(res.data);
                });
            }
        });
    }
}
