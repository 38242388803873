import { Plugins } from "@capacitor/core";
import { DeviceService } from "app/shared/device.service";
import { AbstractRecorder } from "./abstract-recorder";
import { MediaRecorderComponent } from "./media-recorder.component";
import { VideoRecorder, VideoRecorderCamera, VideoRecorderPreviewFrame } from '@teamhive/capacitor-video-recorder';
import { Router } from "@angular/router";

export class iOSVideoRecorder extends AbstractRecorder {
    component: MediaRecorderComponent;
    config: VideoRecorderPreviewFrame;

    constructor(component, deviceService: DeviceService, router: Router) {
        console.log('setting up ios video recorder.');
        super(component, deviceService, router);

        // set the initial state of the player
        let recorderPositionX;
        let recorderPositionY;
        let recorderHeight;
        let recorderWidth;

        if (this.deviceService.attemptStyle() === 'row') {
            recorderPositionX = (window.innerWidth - (window.innerWidth / 2));
            recorderPositionY = 30;
            recorderHeight = window.innerHeight - 80;
            recorderWidth = window.innerWidth / 2;
        } else {
            recorderPositionX = 0;
            recorderPositionY = (window.innerHeight / 2) + 30;
            recorderHeight = (window.innerHeight / 2) - 80;
            recorderWidth = 'fill';
        }

        this.config = {
            id: 'video-record',
            stackPosition: 'front',
            width: recorderWidth,
            height: recorderHeight,
            x: recorderPositionX,
            y: recorderPositionY,
            borderRadius: 0
        };

        this.component.destoryIosPlayer.subscribe(res => {
            if (res) {
                this.destroy();
            }
        });
    }

    destroy() {
        this.state = 'stopped';
        VideoRecorder.destroy();
    }

    connectAndPreview() {
        return new Promise<null>((resolve, reject) => {
            this.component.setPlayerMode('preview');
            this.state = 'ready';
            VideoRecorder.initialize({
                camera: VideoRecorderCamera.FRONT,
                previewFrames: [this.config]
            });
            resolve(null)
        });
    }

    start() {
        super.start();
        this.state = 'recording';
        this.startTime = Date.now();
        VideoRecorder.startRecording();
    }

    async stop() {
        super.stop();
        this.state = 'stopped';
        const res = await VideoRecorder.stopRecording();
        if (res && res.videoUrl) {
            this.processVideo(res.videoUrl);
        } else {
            console.log('Can\'t find videoURL');
            VideoRecorder.destroy();
        }
    }

    rerecord() {
        this.connectAndPreview().then(() => {
            this.start();
        });
    }

    processVideo(video) {
        this.state = 'recorded';
        const duration = Date.now() - this.startTime;

        this.getFileFromURL(video).then(blob => {
            this.component.zone.run(() => {
                this.component.iosVideoCapture.emit([blob, video + '#t=0.02', duration, this.eventLog]);
                super.recordingComplete();
                clearInterval(this.interval);
            });
        }, error => {
            console.log('Process video on error', error);
            this.getFileFromURL(video)
        });
        VideoRecorder.destroy();
    }

    async getFileFromURL(filePath: string): Promise<Blob> {
        const response = await fetch(filePath);
        let blob = await response.blob();
        blob = blob.slice(0, blob.size, 'video/mp4');
        return blob;
    }
}
