import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject, Subject } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AccountService {
    profile = new Subject<any>();
    profilePicture = new BehaviorSubject<any>(null);
    fullName: string;
    userName: string;

    constructor(
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.authService.token.subscribe(token => {
            if (token) {
                this.http.get(`${environment.NApiDomain}/${token}/profile`).subscribe((res: any) => {
                    this.profile.next(res);
                    this.profilePicture.next(res.profileImage);
                    this.fullName = `${res.FirstName} ${res.LastName}`;
                    this.userName = res.UserName;
                });
            } else {
                this.profile.next(null);
            }
        })
    }
}
